<template>
  <div class="row">
    <div class="col-12">
      <card-formularios :title="table.title" :subTitle="table.subTitle">
        <div slot="raw-content" class="table-responsive">
          <matrimonios-table
            type="hover"
            :title="table.title"
            :sub-title="table.subTitle"
            :data="table.data"
            :columns="table.columns"
          >
          </matrimonios-table>
        </div>
      </card-formularios>

    </div>
  </div>
</template>

<script>
import { MatrimoniosTable } from "@/components";
import CardFormularios from '@/components/Cards/CardMatrimonios.vue';
import matrimoniosService from '@/services/matrimonios_service';

const tableColumns = [
  "id",
  "NombreEsposo",
  "TipoMatrimonio",
];

export default {
  components: {
    MatrimoniosTable,
    CardFormularios,
  },
  data() {
    return {
      table: {
        title: "Tabla Registro Matrimonios",
        subTitle: "Datos activos de matrimonios",
        columns: tableColumns,
        data: []
      },
      datosGenerales: [],
    };
  },

  async mounted() {
    try {
        const response = await matrimoniosService.fetchMatrimonios();
        const matrimonios = response.data.data;

        // Procesa los datos recibidos para extraer lo que necesitas
        this.table.data = matrimonios.map(matrimonio => {
            return {
                // Aquí defines cómo se mapean los datos de `matrimonio` a los campos de tu tabla
                id: matrimonio.id,
                TipoMatrimonio: matrimonio.attributes.DatosGenerales.TipoMatrimonio,
                NombreEsposo: matrimonio.attributes.DatosGenerales.NombreEsposo,
                IdentidadEsposo: matrimonio.attributes.DatosGenerales.IdentidadEsposo,
                ProfesionEsposo: matrimonio.attributes.DatosGenerales.ProfesionEsposo,
                CelularEsposo: matrimonio.attributes.DatosGenerales.CelularEsposo,
                CorreoEsposo: matrimonio.attributes.DatosGenerales.CorreoEsposo,
                MembresiaEsposo: matrimonio.attributes.DatosGenerales.MembresiaEsposo,
                EmpleadoHiguertropicEsposo: matrimonio.attributes.DatosGenerales.EmpleadoHiguertropicEsposo,
                NombreEsposa: matrimonio.attributes.DatosGenerales.NombreEsposa,
                IdentidadEsposa: matrimonio.attributes.DatosGenerales.IdentidadEsposa,
                ProfesionEsposa: matrimonio.attributes.DatosGenerales.ProfesionEsposa,
                CelularEsposa: matrimonio.attributes.DatosGenerales.CelularEsposa,
                CorreoEsposa: matrimonio.attributes.DatosGenerales.CorreoEsposa,
                MembresiaEsposa: matrimonio.attributes.DatosGenerales.MembresiaEsposa,
                EmpleadoHiguertropicEsposa: matrimonio.attributes.DatosGenerales.EmpleadoHiguertropicEsposa,
                Departamento: matrimonio.attributes.DatosGenerales?.Departamento?.data?.attributes?.Nombre,
                Municipio: matrimonio.attributes.DatosGenerales?.Municipio?.data?.attributes?.Nombre,
                Direccion: matrimonio.attributes.DatosGenerales.Direccion,
                NumeroHijos: matrimonio.attributes.DatosGenerales.NumeroHijos,
                NombreH1: matrimonio.attributes.DatosGenerales.NombreH1,
                EdadH1: matrimonio.attributes.DatosGenerales.EdadH1,
                NombreH2: matrimonio.attributes.DatosGenerales.NombreH2,
                EdadH2: matrimonio.attributes.DatosGenerales.EdadH2,
                NombreH3: matrimonio.attributes.DatosGenerales.NombreH3,
                EdadH3: matrimonio.attributes.DatosGenerales.EdadH3,
                NombreH4: matrimonio.attributes.DatosGenerales.NombreH4,
                EdadH4: matrimonio.attributes.DatosGenerales.EdadH4,
                NombreH5: matrimonio.attributes.DatosGenerales.NombreH5,
                EdadH5: matrimonio.attributes.DatosGenerales.EdadH5,
                NombreH6: matrimonio.attributes.DatosGenerales.NombreH6,
                EdadH6: matrimonio.attributes.DatosGenerales.EdadH6,
                MatrimonioAsesor: matrimonio.attributes.DatosGenerales.MatrimonioAsesor,
                NombreEsposoAsesor: matrimonio.attributes.DatosGenerales.NombreEsposoAsesor,
                CelularEsposoAsesor: matrimonio.attributes.DatosGenerales.CelularEsposoAsesor,
                CorreoEsposoAsesor: matrimonio.attributes.DatosGenerales.CorreoEsposoAsesor,
                MembresiaEsposoAsesor: matrimonio.attributes.DatosGenerales.MembresiaEsposoAsesor,
                EmpleadoEsposoAsesor: matrimonio.attributes.DatosGenerales.EmpleadoEsposoAsesor,
                NombreEsposaAsesora: matrimonio.attributes.DatosGenerales.NombreEsposaAsesora,
                CelularEsposaAsesora: matrimonio.attributes.DatosGenerales.CelularEsposaAsesora,
                CorreoEsposaAsesora: matrimonio.attributes.DatosGenerales.CorreoEsposaAsesora,
                MembresiaEsposaAsesora: matrimonio.attributes.DatosGenerales.MembresiaEsposaAsesora,
                EmpleadoEsposaAsesora: matrimonio.attributes.DatosGenerales.EmpleadoEsposaAsesora,

                NombreIglesiaActual: matrimonio.attributes.OtrosDatos.NombreIglesiaActual,
                PastorOSacerdote: matrimonio.attributes.OtrosDatos.PastorOSacerdote,
                CelularPastor: matrimonio.attributes.OtrosDatos.CelularPastor,
                CorreoPastor: matrimonio.attributes.OtrosDatos.CorreoPastor,
                TiempoActivo: matrimonio.attributes.OtrosDatos.TiempoActivo,
                Cargo: matrimonio.attributes.OtrosDatos.Cargo,
            };

        });
        console.log('table.data:', this.table.data);

    } catch (error) {
        console.error('Error fetching data:', error);
    }
},

  methods: {
    formatWithPrefix(label, value) {
      return value ? `\n\t${label}: ${value}` : '';
    },

}
};
</script>

<style></style>
