<template>
  <div class="modal" :class="{ show: showModal }" tabindex="-1" role="dialog" @click.self="close">
    <div class="modal-dialog modal-lg" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Detalles del Registro</h5>
          <button type="button" class="close" aria-label="Close" @click="close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <ul>
            <li v-for="(value, key) in filteredDetails" :key="key">
              <strong>{{ key }}:</strong>
              <template v-if="typeof value === 'object' && value !== null">
                <ul>
                  <li v-for="(subValue, subKey) in value" :key="subKey">
                    <strong>{{ subKey }}:</strong> {{ subValue }}
                  </li>
                </ul>
              </template>
              <template v-else>
                {{ value }}
              </template>
            </li>
          </ul>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" @click="close">Cerrar</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    showModal: {
      type: Boolean,
      required: true
    },
    details: {
      type: Object,
      required: true
    }
  },
  computed: {
    filteredDetails() {
      if (!this.details) {
        return {};
      }
      //NOTA PRIOPIA> AQUI SE CAMBIAN LOS CAMPOS DEL MODAL
      const keysToShow = [

      "TipoMatrimonio", "NombreEsposo", "IdentidadEsposo", "ProfesionEsposo", "CelularEsposo", "CorreoEsposo", "MembresiaEsposo", "EmpleadoHiguertropicEsposo",
       "NombreEsposa", "IdentidadEsposa", "ProfesionEsposa", "CelularEsposa", "CorreoEsposa", "MembresiaEsposa", "EmpleadoHiguertropicEsposa",
        "Departamento", "Municipio", "Direccion", "NumeroHijos", "NombreH1", "EdadH1", "NombreH2", "EdadH2", "NombreH3", "EdadH3", "NombreH4", "EdadH4",
        "NombreH5", "EdadH5", "NombreH6", "EdadH6", "MatrimonioAsesor", "NombreEsposoAsesor", "CelularEsposoAsesor", "CorreoEsposoAsesor",
        "MembresiaEsposoAsesor", "EmpleadoEsposoAsesor", "NombreEsposaAsesora", "CelularEsposaAsesora", "CorreoEsposaAsesora", "MembresiaEsposaAsesora",
        "EmpleadoEsposaAsesora", "NombreIglesiaActual", "PastorOSacerdote", "CelularPastor", "CorreoPastor", "TiempoActivo", "Cargo"

      ];
      return Object.keys(this.details)
        .filter(key => keysToShow.includes(key) && this.details[key] !== undefined)
        .reduce((obj, key) => {
          obj[key] = this.details[key];
          return obj;
        }, {});
    }
  },
  methods: {
    close() {
      this.$emit('close');
    },
  }
};
</script>

<style scoped>
.modal {
  display: none;
}
.modal.show {
  display: block;
  background-color: rgba(0, 0, 0, 0.5);
}
.modal-body {
  max-height: 400px; /* Adjust the height as needed */
  overflow-y: auto; /* Enables vertical scrolling if the content exceeds the max-height */
}
</style>
