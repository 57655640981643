<template>
  <div class="row">
<!-- Se cambio el nombre EditProfileForm a InscripcionMembresiaForm -->
    <div class="col-xl-12 col-lg-7 col-md-6">
      <MembresiasTable></MembresiasTable>
    </div>
  </div>
</template>
<script>
import MembresiasTable from "@/tables/MembresiasTable.vue";

export default {
  components: {
    MembresiasTable,

  },
};
</script>
<style></style>


