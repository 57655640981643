<template>
  <div class="row">
    <div class="col-12">
      <card-formularios :title="table.title" :subTitle="table.subTitle">
        <div slot="raw-content" class="table-responsive">
          <solicitud-empleo-table
            :columns="table.columns"
            :data="table.data"
            @showDetail="showDetail"
          ></solicitud-empleo-table>
        </div>
      </card-formularios>

    </div>
  </div>
</template>

<script>
import { SolicitudEmpleoTable } from "@/components"; //AQUI ME QUEDE AQUI ME QUEDE
import CardFormularios from '@/components/Cards/CardSolicitudEmpleo.vue';
import solicitudEmpleoService from '@/services/solicitud_empleo_service';

const tableColumns = [
  "id",
  "Nombre",
  "NumeroMembresia",
];

export default {
  components: {
    SolicitudEmpleoTable,
    CardFormularios,
  },
  data() {
    return {
      table: {
        title: "Tabla Solicitudes de Empleo",
        subTitle: "Datos activos de solicitudes de empleo",
        columns: tableColumns,
        data: []
      },
      // datosGenerales: [],
    };
  },

  async mounted() {
    try {
        const response = await solicitudEmpleoService.fetchSolicitudEmpleo();
        const solicitud_empleo = response.data.data;

        // Procesa los datos recibidos para extraer lo que necesitas
        this.table.data = solicitud_empleo.map(solicitud => {
            return {
                // Aquí defines cómo se mapean los datos de `matrimonio` a los campos de tu tabla
                id: solicitud.id,
                Lugar: solicitud.attributes.DatosGenerales.Lugar,
                Fecha: solicitud.attributes.DatosGenerales.Fecha,
                NumeroMembresia: solicitud.attributes.DatosGenerales.NumeroMembresia,
                Municipio: solicitud.attributes.DatosGenerales?.Municipio?.data?.attributes?.Nombre,
                Departamento: solicitud.attributes.DatosGenerales?.Departamento?.data?.attributes?.Nombre,
                Zona: solicitud.attributes.DatosGenerales.Zona,
                Nombre: solicitud.attributes.DatosGenerales.Nombre,
                Direccion: solicitud.attributes.DatosGenerales.Direccion,
                PuestoSolicita: solicitud.attributes.DatosGenerales.PuestoSolicita,
                Sueldo: solicitud.attributes.DatosGenerales.Sueldo,
                Identidad: solicitud.attributes.DatosGenerales.Identidad,
                LugarNacimiento: solicitud.attributes.DatosGenerales.LugarNacimiento,
                FechaNacimiento: solicitud.attributes.DatosGenerales.FechaNacimiento,
                EstadoCivil: solicitud.attributes.DatosGenerales.EstadoCivil,
                TelefonoFijo: solicitud.attributes.DatosGenerales.TelefonoFijo,
                CorreoElectronico: solicitud.attributes.DatosGenerales.CorreoElectronico,
                Celular: solicitud.attributes.DatosGenerales.Celular,
                IHSS: solicitud.attributes.DatosGenerales.IHSS,
                TipoSangre: solicitud.attributes.DatosGenerales.TipoSangre,
                CasaPropia: solicitud.attributes.DatosGenerales.CasaPropia,
                ValorCasa: solicitud.attributes.DatosGenerales.ValorCasa,
                AlquilerCasa: solicitud.attributes.DatosGenerales.AlquilerCasa,
                Ciudad: solicitud.attributes.DatosGenerales?.Ciudad?.data?.attributes?.Nombre,
                DepartamentoZona: solicitud.attributes.DatosGenerales?.DepartamentoZona?.data?.attributes?.Nombre,
                CuantosTrabajan: solicitud.attributes.DatosGenerales.CuantosTrabajan,
                PoseeVehiculo: solicitud.attributes.DatosGenerales.PoseeVehiculo,
                MarcaVehiculo: solicitud.attributes.DatosGenerales.MarcaVehiculo,
                PlacaVehiculo: solicitud.attributes.DatosGenerales.PlacaVehiculo,
                AnioVehiculo: solicitud.attributes.DatosGenerales.AnioVehiculo,
                TipoLicencia: solicitud.attributes.DatosGenerales.TipoLicencia,
                VencimientoLicencia: solicitud.attributes.DatosGenerales.VencimientoLicencia,
                OtroVehiculo: solicitud.attributes.DatosGenerales.OtroVehiculo,
                Salud: solicitud.attributes.DatosGenerales.Salud,
                BebidasAlcoholicas: solicitud.attributes.DatosGenerales.BebidasAlcoholicas,
                BebidasFrecuencia: solicitud.attributes.DatosGenerales.BebidasFrecuencia,
                EstudiaNoches: solicitud.attributes.DatosGenerales.EstudiaNoches,
                NombrePadre: solicitud.attributes.DatosGenerales.NombrePadre,
                DireccionPadres: solicitud.attributes.DatosGenerales.DireccionPadres,
                Conyugue: solicitud.attributes.DatosGenerales.Conyugue,
                Hijos: solicitud.attributes.DatosGenerales.Hijos,
                CCorreoElectronico: solicitud.attributes.DatosGenerales.CCorreoElectronico,
                CTelefonoFijo: solicitud.attributes.DatosGenerales.CTelefonoFijo,
                CCelular: solicitud.attributes.DatosGenerales.CCelular,

                Profesion: solicitud.attributes.NivelAcademico.Profesion,
                Escuela: solicitud.attributes.NivelAcademico.Escuela,
                EscuelaGrado: solicitud.attributes.NivelAcademico.EscuelaGrado,
                Insttituto: solicitud.attributes.NivelAcademico.Insttituto,
                InstritutoGrado: solicitud.attributes.NivelAcademico.InstritutoGrado,
                TituloSecundaria: solicitud.attributes.NivelAcademico.TituloSecundaria,
                Universidad: solicitud.attributes.NivelAcademico.Universidad,
                TituloUniversidad: solicitud.attributes.NivelAcademico.TituloUniversidad,
                Cursos: solicitud.attributes.NivelAcademico.Cursos,
                Equipos: solicitud.attributes.NivelAcademico.Equipos,
                ServicioMilitar: solicitud.attributes.NivelAcademico.ServicioMilitar,
                UnidadSM: solicitud.attributes.NivelAcademico.UnidadSM,
                LugarSM: solicitud.attributes.NivelAcademico.LugarSM,
                PeriodoSM: solicitud.attributes.NivelAcademico.PeriodoSM,
                ComandanteSM: solicitud.attributes.NivelAcademico.ComandanteSM,
                RangoSM: solicitud.attributes.NivelAcademico.RangoSM,
                RegistroSM: solicitud.attributes.NivelAcademico.RegistroSM,

                NegocioPropio: solicitud.attributes.EmpleosAnteriores.NegocioPropio,
                NombreE1: solicitud.attributes.EmpleosAnteriores.NombreE1,
                TelefonoE1: solicitud.attributes.EmpleosAnteriores.TelefonoE1,
                SueldoIE1: solicitud.attributes.EmpleosAnteriores.SueldoIE1,
                SueldoFE1: solicitud.attributes.EmpleosAnteriores.SueldoFE1,
                FechaIE1: solicitud.attributes.EmpleosAnteriores.FechaIE1,
                PuestoE1: solicitud.attributes.EmpleosAnteriores.PuestoE1,
                ObligacionesE1: solicitud.attributes.EmpleosAnteriores.ObligacionesE1,
                NombreE2: solicitud.attributes.EmpleosAnteriores.NombreE2,
                TelefonoE2: solicitud.attributes.EmpleosAnteriores.TelefonoE2,
                SueldoIE2: solicitud.attributes.EmpleosAnteriores.SueldoIE2,
                SueldoFE2: solicitud.attributes.EmpleosAnteriores.SueldoFE2,
                FechaIE2: solicitud.attributes.EmpleosAnteriores.FechaIE2,
                PuestoE2: solicitud.attributes.EmpleosAnteriores.PuestoE2,
                ObligacionesE2: solicitud.attributes.EmpleosAnteriores.ObligacionesE2,
                NombreE3: solicitud.attributes.EmpleosAnteriores.NombreE3,
                TelefonoE3: solicitud.attributes.EmpleosAnteriores.TelefonoE3,
                SueldoIE3: solicitud.attributes.EmpleosAnteriores.SueldoIE3,
                SueldoFE3: solicitud.attributes.EmpleosAnteriores.SueldoFE3,
                FechaIE3: solicitud.attributes.EmpleosAnteriores.FechaIE3,
                PuestoE3: solicitud.attributes.EmpleosAnteriores.PuestoE3,
                ObligacionesE3: solicitud.attributes.EmpleosAnteriores.ObligacionesE3,

                Habilidad1: solicitud.attributes.Habilidades.Habilidad1,
                Habilidad2: solicitud.attributes.Habilidades.Habilidad2,

                NombreP1: solicitud.attributes.PersonasConocen.NombreP1,
                TelefonoP1: solicitud.attributes.PersonasConocen.TelefonoP1,
                DireccionP1: solicitud.attributes.PersonasConocen.DireccionP1,
                NombreP2: solicitud.attributes.PersonasConocen.NombreP2,
                TelefonoP2: solicitud.attributes.PersonasConocen.TelefonoP2,
                DireccionP2: solicitud.attributes.PersonasConocen.DireccionP2,
                NombreP3: solicitud.attributes.PersonasConocen.NombreP3,
                TelefonoP3: solicitud.attributes.PersonasConocen.TelefonoP3,
                DireccionP3: solicitud.attributes.PersonasConocen.DireccionP3,

                NombreF1: solicitud.attributes.Familiares.NombreF1,
                TelefonoF1: solicitud.attributes.Familiares.TelefonoF1,
                DireccionF1: solicitud.attributes.Familiares.DireccionF1,
                NombreF2: solicitud.attributes.Familiares.NombreF2,
                TelefonoF2: solicitud.attributes.Familiares.TelefonoF2,
                DireccionF2: solicitud.attributes.Familiares.DireccionF2,
                NombreF3: solicitud.attributes.Familiares.NombreF3,
                TelefonoF3: solicitud.attributes.Familiares.TelefonoF3,
                DireccionF3: solicitud.attributes.Familiares.DireccionF3,

            };

        });
        console.log('table.data:', this.table.data);

    } catch (error) {
        console.error('Error fetching data:', error);
    }
},

  methods: {
    formatWithPrefix(label, value) {
      return value ? `\n\t${label}: ${value}` : '';
    },

}
};
</script>

<style></style>
