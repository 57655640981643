<template>
  <div class="row">
    <div class="col-12">
      <card-formularios :title="table.title" :subTitle="table.subTitle">
        <div slot="raw-content" class="table-responsive">
          <membresias-table
            type="hover"
            :title="table.title"
            :sub-title="table.subTitle"
            :data="table.data"
            :columns="table.columns"
          >
          </membresias-table>
        </div>
      </card-formularios>

    </div>
  </div>
</template>

<script>
import { MembresiasTable } from "@/components";
import CardFormularios from '@/components/Cards/CardMembresias.vue';
import membresiasService from '@/services/membresias_service';

const tableColumns = [
  "id",
  "Numero Membresia",
  "Nombre Completo",

  // Add additional columns as needed
];

export default {
  components: {
    MembresiasTable,
    CardFormularios,
  },
  data() {
    return {
      table: {
        title: "Tabla Registro Membresias",
        subTitle: "Datos activos de miembros",
        columns: tableColumns,
        data: []
      },
      datosgenerales: [],
      membresias: [],
      datosprofesionales: [],
      datosempresariales: [],
      datosespirituales: [],
    };
  },

async mounted() {
    try {
        const response = await membresiasService.fetchMembresias();
        const membresias = response.data.data;
        console.log('membresias en el sistema:', membresias);

        // Procesa los datos recibidos para extraer lo que necesitas
        this.table.data = membresias.map(membresia => {
            return {
              id: membresia.id,
              NumeroMembresia: membresia.attributes.Ubicacion.NumeroMembresia,
              ZonaMic: membresia.attributes.Ubicacion.ZonaMic?.data?.attributes?.Nombre,
              Localidad: membresia.attributes.Ubicacion.Localidad?.data?.attributes?.Nombre,
              Departamento: membresia.attributes.Ubicacion.Departamento?.data?.attributes?.Nombre,
              Municipio: membresia.attributes.Ubicacion.Municipio?.data?.attributes?.Nombre,
              Zona: membresia.attributes.Ubicacion.Zona?.data?.attributes?.Nombre,

              NombreCompleto: membresia.attributes.DatosGenerales.NombreCompleto,
              NumeroIdentidad: membresia.attributes.DatosGenerales.NumeroIdentidad,
              EstadoCivil: membresia.attributes.DatosGenerales.EstadoCivil,
              NombreEsposa: membresia.attributes.DatosGenerales.NombreEsposa,
              Domicilio: membresia.attributes.DatosGenerales.Domicilio,
              TelefonoFijo: membresia.attributes.DatosGenerales.TelefonoFijo,
              CorreoElectronico: membresia.attributes.DatosGenerales.CorreoElectronico,
              Celular: membresia.attributes.DatosGenerales.Celular,
              IHSS: membresia.attributes.DatosGenerales.IHSS,
              TipoSangre: membresia.attributes.DatosGenerales.TipoSangre,
              FechaNacimiento: membresia.attributes.DatosGenerales.FechaNacimiento,

              Profesion: membresia.attributes.DatosProfesionales.Profesion,
              Primaria: membresia.attributes.DatosProfesionales.Primaria,
              Secundaria: membresia.attributes.DatosProfesionales.Secundaria,
              Superior: membresia.attributes.DatosProfesionales.Superior,
              Cursos: membresia.attributes.DatosProfesionales.Cursos,
              CargosPublicos: membresia.attributes.DatosProfesionales.CargosPublicos,

              NombreEmpresa: membresia.attributes.DatosEmpresariales.NombreEmpresa,
              Direccion: membresia.attributes.DatosEmpresariales.Direccion,
              Cargo: membresia.attributes.DatosEmpresariales.Cargo,
              ActividadPrincipal: membresia.attributes.DatosEmpresariales.ActividadPrincipal,
              Telefono: membresia.attributes.DatosEmpresariales.Telefono,

              FechaIngreso: membresia.attributes.DatosEspirituales.FechaIngreso,
              Cargos: membresia.attributes.DatosEspirituales.Cargos,
              CargoActual: membresia.attributes.DatosEspirituales.CargoActual,
              CargoPasado: membresia.attributes.DatosEspirituales.CargoPasado,
              MiembroActivo: membresia.attributes.DatosEspirituales.MiembroActivo,
              Seminario: membresia.attributes.DatosEspirituales.Seminario,
              MiembroInvitador: membresia.attributes.DatosEspirituales.MiembroInvitador,
              Nombre: membresia.attributes.DatosEspirituales.Nombre,
              PastorOSacerdote: membresia.attributes.DatosEspirituales.PastorOSacerdote,
              Celular: membresia.attributes.DatosEspirituales.Celular,
              CorreoElectronico: membresia.attributes.DatosEspirituales.CorreoElectronico,
              CargosIA: membresia.attributes.DatosEspirituales.CargosIA,
              TiempoServicio: membresia.attributes.DatosEspirituales.TiempoServicio,
              CargoActualIA: membresia.attributes.DatosEspirituales.CargoActualIA,
              CargoPasadoIA: membresia.attributes.DatosEspirituales.CargosPasadoIA,
              Test:  membresia.attributes.DatosEspirituales.Test?.data?.attributes?.Testimonio,
              Don:  membresia.attributes.DatosEspirituales.Don?.data?.attributes?.DonesEsprirituales,


              };



        });
        console.log('table.data:', this.table.data);

    } catch (error) {
        console.error('Error fetching data:', error);
    }
},
};
</script>

<style></style>

