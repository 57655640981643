<template>
  <div>
    <table class="table" :class="tableClass">
      <thead>
        <slot name="columns">
          <th v-for="column in filteredColumns" :key="column">{{ column }}</th>
        </slot>
      </thead>
      <tbody>
        <tr v-for="(item, index) in paginatedData" :key="index">
          <td v-for="(column, columnIndex) in filteredColumns" :key="columnIndex">
            <template v-if="column === 'id'">
              {{ item.id }}
            </template>
            <template v-else>
              <template v-if="getItemValue(item, column) !== undefined">
                {{ getItemValue(item, column) }}
              </template>
              <template v-else>
                N/A
              </template>
            </template>
          </td>
          <td>
            <button @click="detailsItem(item)" class="btn btn-sm btn-success mr-2">
              <i class="ti-eye button-icon"></i> <!-- Removed the period before button-icon -->
              <span class="button-text">Ver Detalles</span>
            </button>
            <button @click="editItem(item)" class="btn btn-sm btn-info">
              <i class="ti-pencil button-icon"></i> <!-- Removed the period before button-icon -->
              <span class="button-text">Editar</span>
            </button>
          </td>
        </tr>
      </tbody>
    </table>
    <div class="row" style="padding: 5vh;">
      <!-- Column for records count -->
      <div class="col text-left" style="padding: 10px;">
        Mostrando del {{ startRecord }} al {{ endRecord }} de {{ totalRecords }} registros
      </div>
      <!-- Column for pagination, aligned to the right -->
      <div class="col text-right">
        <nav aria-label="Page navigation">
          <ul class="pagination justify-content-end mb-0">
            <li class="page-item" :class="{ disabled: currentPage === 1 }">
              <a class="page-link" href="#" @click.prevent="prevPage">Anterior</a>
            </li>
            <li class="page-item" v-for="page in totalPagesArray" :key="page" :class="{ active: currentPage === page }">
              <a class="page-link" href="#" @click.prevent="setPage(page)">{{ page }}</a>
            </li>
            <li class="page-item" :class="{ disabled: currentPage === totalPages }">
              <a class="page-link" href="#" @click.prevent="nextPage">Siguiente</a>
            </li>
          </ul>
        </nav>
      </div>
    </div>

    <Modal :showModal="showModal" :details="selectedDetail || {}" @close="closeDetailModal" />
  </div>
</template>

<script>
import Modal from './Modal.vue';
import EditarMembresia from '@/pages/EditarMembresias.vue';

export default {
  name: "Membresias",
  components: {
    Modal
  },
  props: {
    columns: Array,
    data: Array,
    type: {
      type: String, // striped | hover
      default: "hover",
    },
    title: {
      type: String,
      default: "",
    },
    subTitle: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      currentPage: 1,
      perPage: 10,
      tableClass: `table-${this.type}`,
      showModal: false,
      selectedDetail: {},
      columnsToShow: [
        "id",
        "Numero Membresia",
        "Nombre Completo",

        // Añadir otras columnas que deseas mostrar
      ]

    };
  },
  computed: {
    totalRecords() {
      return this.data.length;
    },
    totalPages() {
      return Math.ceil(this.totalRecords / this.perPage);
    },
    totalPagesArray() {
      return Array.from({ length: this.totalPages }, (_, i) => i + 1);
    },
    paginatedData() {
      const start = (this.currentPage - 1) * this.perPage;
      const end = start + this.perPage;
      return this.data.slice(start, end);
    },
    startRecord() {
      return (this.currentPage - 1) * this.perPage + 1;
    },
    endRecord() {
      return Math.min(this.currentPage * this.perPage, this.totalRecords);
    },
    filteredColumns() {
      return this.columns.filter(column => this.columnsToShow.includes(column));
    }
  },
  methods: {
    nextPage() {
      if (this.currentPage < this.totalPages) this.currentPage++;
    },
    prevPage() {
      if (this.currentPage > 1) this.currentPage--;
    },
    setPage(page) {
      if (page >= 1 && page <= this.totalPages) this.currentPage = page;
    },
    getItemValue(item, column) {
      const columnKey = column.replace(/\s+/g, ''); // Eliminar espacios para coincidir con las claves en item.attributes
      if (item[columnKey] !== undefined) {
        return item[columnKey];
      }
      if (item.attributes && item.attributes[columnKey] !== undefined) {
        return item.attributes[columnKey];
      }
      return undefined;
    },
    detailsItem(item) {
      this.showDetailModal(item);
    },
    editItem(item) {
      this.$router.push({ name: 'Editar Membresias', params: { id: item.id } });
    },
    showDetailModal(item) {
      this.selectedDetail = { ...item };
      this.showModal = true;
    },
    closeDetailModal() {
      this.selectedDetail = {};
      this.showModal = false;
    }
  }
};
</script>

<style scoped>
.table-hover tbody tr:hover {
  background-color: #f5f5f5;
}

/* Hide icons by default for desktop views */
.button-icon {
  display: none !important;
}

/* Mobile devices */
@media (max-width: 768px) {
  .button-icon {
    display: inline-block !important; /* Show icons on mobile */
  }
  .col.text-right {
    align-items: center !important;
  }
  .col.text-left {
    text-align: center !important;
  }
  .button-text {
    display: none !important; /* Optionally hide the text on mobile if desired */
  }
  .pagination {
    justify-content: center !important;
  }
}


</style>






