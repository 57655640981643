<template>
  <div id="app" class="html">
    <div class="loginBox">
      <div class="inner">
        <div class="signIn" v-if="signIn">
          <div class="top">
            <img class="logo" src="@/assets/img/LogoMic.png" width="100px" />
            <div class="title">Bienvenido</div>
            <div class="subtitle">
              <p>Ingrese sus datos</p>
            </div>
          </div>
          <form @submit.prevent="login">
            <div class="form">
              <input
                required
                type="text"
                class="w100"
                placeholder="Usuario"
                autofocus
                v-model="usuario.value"
              />
              <input
                required
                type="password"
                class="w100"
                placeholder="Contraseña"
                v-model="contrasenia.value"
              />
            </div>
            <div class="text-center">
              <button class="btn btn-info" style="margin-top: 20px;" type="submit" :disabled="loading">
                Iniciar Sesión
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
    <div v-if="loading" class="overlay">
      <div class="spinner"></div>
    </div>
  </div>
</template>


<script>
export default {
  data() {
    return {
      contrasenia: {
        value: "",
        error: false
      },
      usuario: {
        value: "",
        error: false
      },
      signIn: true,
      loading: false,
    };
  },
  methods: {
    async login() {
      // Validate user input
      if (!this.usuario.value || !this.contrasenia.value) {
        alert('Por favor ingrese ambos campos');
        return;
      }
      this.loading = true; // Start loading
      try {
        const response = await fetch(`${process.env.VUE_APP_API_URL}/auth/local`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            identifier: this.usuario.value,
            password: this.contrasenia.value
          })
        });
        if (response.ok) {
          const data = await response.json();
          // Store the JWT token and update the UI
          sessionStorage.setItem('jwt', data.jwt);
          this.$router.push('/dashboard'); // Redirect to the dashboard
          this.$toast('👋 Bienvenido a MIC Los Granados - Roca Fuerte', {
            position: "bottom-center",
            timeout: 5000,
            icon: false,
            hideProgressBar: true,
          });
        } else {
          // Handle non-JSON error responses
          const errorText = await response.text();
          console.error('Login failed:', errorText);
          this.$toast.error('Error al iniciar sesion', {
            position: "top-right",
            timeout: 5000,
          });
        }
      } catch (error) {
        console.error('An error occurred:', error);
        this.$toast.error('Ocurrio un error, intentelo de nuevo mas tarde', {
          position: "top-right",
          timeout: 5000,
        });
      } finally {
        this.loading = false; // Stop loading
      }
    },
    getToken() {
      return localStorage.getItem('jwt');
    },
    async fetchWithToken(url, options = {}) {
      const token = this.getToken();
      const headers = {
        'Content-Type': 'application/json',
        ...options.headers,
        'Authorization': `Bearer ${token}`
      };
      const response = await fetch(url, {
        ...options,
        headers
      });
      return response;
    }
  }
};
</script>
<style lang="scss" scoped>
/* Estilo para el overlay de carga */
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

/* Estilo para el spinner */
.spinner {
  border: 4px solid rgba(0, 0, 0, 0.1);
  width: 36px;
  height: 36px;
  border-radius: 50%;
  border-left-color: #09f;
  animation: spin 1s ease infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@mixin box {
  box-shadow: 1px 1px 2px 1px #ccc;
}

@mixin field {
  border: 1px solid #aaa;
  height: 40px;
  padding: 10px;
  margin-top: 20px;
  border-radius: 5px;
  box-sizing: border-box;
}

input[type="text"] {
  @include field;
}

input[type="email"] {
  @include field;
}

input[type="password"] {
  @include field;
}

.invalid {
  border: 2px solid rgb(151, 27, 27) !important;
  &::placeholder {
    color: rgb(148, 38, 38);
  }
}

.errorMessage {
  color: rgb(114, 30, 30);
  margin: 10px;
  top: 5px;
}

.w100 {
  width: 100%;
}

.logo {
  width: 200px;
  margin-bottom: 10px;
}

.action {
  height: 40px;
  text-transform: uppercase;
  border-radius: 25px;
  width: 100%;
  border: none;
  cursor: pointer;
  background: green;
  margin-top: 20px;
  color: #fff;
  font-size: 1.2rem;
  @include box;
}


.top {
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-bottom: 10px;
}

.title {
  width: 100%;
  font-size: 1.8rem;
  margin-bottom: 10px;
  text-align: center;
}

.subtitle {
  .subtitle-action {
    color: green;
    font-weight: bold;
    cursor: pointer;
  }
}

.html {
  background-repeat: no-repeat;
  background: linear-gradient(
    to bottom,
    rgb(73, 73, 73) 0%,
    rgb(114, 114, 114) 100%
  );
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  font-family: sans-serif;
}

.loginBox {
  background: #fff;
  border-radius: 15px;
  max-width: 400px;
  padding: 25px 55px;
  animation: slideInTop 1s;
}

@keyframes slideInTop {
  from {
    opacity: 0;
    transform: translateY(-30%);
  }

  to {
    opacity: 100;
    transform: translateY(0%);
  }
}

@media screen and (min-width: 440px) {
  .loginBox {
    @include box;
  }
}

@media screen and (max-width: 440px) {
  html {
    background: #fff;
    align-items: start;
    justify-content: start;
  }

  .loginBox {
    padding: 25px 25px;
    max-width: 100vw;
  }
}

</style>

