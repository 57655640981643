<template>
  <footer class="footer">
    <div class="container-fluid d-flex flex-wrap justify-content-between">
      <nav>
        <ul>
          <li>
            <router-link :to="{ path: '/admin' }">MIC LOS GRANADOS - ROCA FUERTE</router-link>
          </li>
        </ul>
      </nav>
      <div class="copyright d-flex flex-wrap">
        &copy; Coded with
        <i class="fa fa-heart heart"></i> by
        <a href="https://arcatechn.com" target="_blank">
          &nbsp; Arca Technologies. </a
        >
      </div>
    </div>
  </footer>
</template>
<script>
export default {};
</script>
<style></style>
