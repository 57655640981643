<template>
  <div class="membresia-form-page">
    <EditarSolicitudEmpleoForm :id="id" />
  </div>
</template>

<script>
import EditarSolicitudEmpleoForm from '@/pages/Formularios/EditarSolicitudEmpleoForm.vue';


export default {
  name: "MembresiaFormPage",
  components: {
    EditarSolicitudEmpleoForm,
  },
  data() {
    return {
      id: this.$route.params.id, // Asegúrate de que el id se obtenga correctamente de las rutas

    };

  },
  created() {
    console.log('El id del registro es: ',this.id);
  }

};
</script>

<style scoped>
.membresia-form-page {
  padding: 20px;
}
</style>
