import apiClient from './api';

export default {
  fetchSolicitudEmpleo() {
    return apiClient.get('/empleos?populate[0]=DatosGenerales&populate[1]=DatosGenerales.Departamento&populate[2]=DatosGenerales.Municipio&populate[3]=NivelAcademico&populate[4]=EmpleosAnteriores&populate[5]=Habilidades&populate[6]=PersonasConocen&populate[7]=Familiares&populate[8]=DatosGenerales.Ciudad&populate[9]=DatosGenerales.DepartamentoZona');
  },

  // Metodos GET para cargar los datos en los combobox en Marimonios
  getCiudades() {
    return apiClient.get('/municipios');
  },
  getDepartamentos() {
    return apiClient.get('/departamentos');
  },

  // Metodos POST para guardar los datos de Matrimonios
  postSolicitudEmpleo(data) {
    return apiClient.post('/empleos', { data }).then(response => response.data);;
  },

  //Metodos PUT para actualizar los datos de Matrimonios
  putSolicitudEmpleo(id, data) {
    return apiClient.put(`/empleos/${id}`, { data });
  },
}
