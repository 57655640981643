<template>
  <div class="membresia-form-page">
    <SolicitudEmpleoForm />
  </div>
</template>

<script>
import SolicitudEmpleoForm from '@/pages/Formularios/SolicitudEmpleoForm.vue';

export default {
  name: "MembresiaFormPage",
  components: {
    SolicitudEmpleoForm,
  },
};
</script>

<style scoped>
.membresia-form-page {
  padding: 20px;
}
</style>
