<template>
  <div>
    <card class="card" title="Formato Inscripción Membresías" subTitle="Solicito muy repestuosamente a la SECRETARÍA NACIONAL DE MEMBRESIA de MIC LOS GRANADOS mi inscripción como miembro de esta institución para lo cual proporciono los siguientes datos">
      <div v-if="errores && Object.keys(errores).length" class="alert alert-warning" ref="erroresContainer">
        <p v-for="(mensaje, campo) in errores" :key="campo">{{ campo }} inválido: {{ mensaje }}</p>
      </div>
      <div>
        <form @submit.prevent="crearMembresia">
          <!-- Tabla Datos Membresia -->
          <div class="row">
            <div class="col-md-6">
              <label>Mision Cristiana Local a la que desea pertenecer</label>
              <multiselect
                class="custom-multiselect"
                v-model="membresia.ZonaMic"
                :options="zonaMicOptions"
                track-by="id"
                label="nombre"
                :searchable="false"
                :close-on-select="true"
                :clear-on-select="false"
                :allow-empty="false"
                placeholder="Seleccione una opción"
                :show-labels="false"
              ></multiselect>
            </div>
             <!--CODIGO DE SUBIR IMAGEN DESCTIVADO TEMPORALMENTE-->
            <div class="col-md-6" style="text-align: right;">
              <p-button style="margin-right: 20px;"><i class="ti-upload"></i>  Subir una imagen</p-button>
              <img src="@/assets/img/imagen-perfil.png" alt="Subir imagen" width="100" style="margin-right: 40px; opacity: 0.8;">
            </div>
          </div>
          <div class="row">
            <div class="col-md-6">
              <label>Numero de Membresia MIC</label>
              <fg-input class="custom-input"
              type="text"
              v-model="membresia.NumeroMembresia">
            </fg-input>
            </div>
            <div class="col-md-6">
              <label>Registro de Empleo Higuertropic</label>
              <fg-input class="custom-input"
              type="text"
              v-model="membresia.NumeroEmpleadoHiguertropic">
            </fg-input>
            </div>
          </div>

          <div class="row">
            <div class="col-md-6">
              <label>Municipio</label>
              <multiselect
                class="custom-multiselect"
                v-model="membresia.MunicipioId"
                :options="filteredMunicipios"
                :searchable="true"
                :close-on-select="true"
                :clear-on-select="true"
                :allow-empty="false"
                placeholder="Seleccione una opción"
                :show-labels="false"
                label="nombre"
                track-by="id"
                selectLabel=""
                @input="filterCiudades"
              ></multiselect>
            </div>
            <div class="col-md-6">
              <label>Departamento</label>
              <multiselect
                class="custom-multiselect"
                v-model="membresia.DepartamentoId"
                :options="filteredDepartamentos"
                :searchable="true"
                :close-on-select="true"
                :clear-on-select="true"
                :allow-empty="false"
                placeholder="Seleccione una opción"
                :show-labels="false"
                label="nombre"
                track-by="id"
                selectLabel=""
                @input="filterDepartamentos"
              ></multiselect>
            </div>
          </div>

          <div class="row">
            <div class="col-md-6">
              <label>Localidad</label>
              <multiselect
                class="custom-multiselect"
                v-model="membresia.LocalidadId"
                :options="filteredLocalidades"
                :searchable="true"
                :close-on-select="true"
                :clear-on-select="true"
                :allow-empty="false"
                placeholder="Seleccione una opción"
                :show-labels="false"
                label="nombre"
                track-by="id"
                selectLabel=""
                @input="filterLocalidades"
              ></multiselect>
            </div>

            <div class="col-md-6">
              <label>Zona</label>
              <multiselect
                class="custom-multiselect"
                v-model="membresia.Zona"
                :options="zonaOptions"
                :searchable="false"
                :close-on-select="true"
                :clear-on-select="false"
                :allow-empty="false"
                placeholder="Seleccione una opción"
                label="nombre"
                track-by="id"
                :show-labels="false"
              ></multiselect>
            </div>
          </div>

          <!-- Tabla Datos Generales -->
          <h5 style="background-color: #f1f1f1; padding: 10px; text-align: center; color: #252422;">
            <i class="ti-id-badge"></i> Datos Generales
          </h5>
          <div class="row">
            <div class="col-md-6">
              <label>Nombre Completo</label>
              <fg-input class="custom-input"
              type="text"
              v-model="membresia.NombreCompleto">
            </fg-input>
            </div>
            <div class="col-md-6">
              <label>Número Identidad</label>
              <fg-input
                class="custom-input"
                type="text"
                v-model="membresia.NumeroIdentidad"
              ></fg-input>
            </div>
          </div>
          <div class="row">
            <div class="col-md-4">
              <label>Estado Civil</label>
              <multiselect
                class="custom-multiselect"
                v-model="membresia.EstadoCivil"
                :options="estadoCivilOptions"
                :searchable="false"
                :close-on-select="true"
                :clear-on-select="false"
                :allow-empty="false"
                placeholder="Seleccione una opción"
                :show-labels="false"
              ></multiselect>
            </div>
            <div class="col-md-4">
              <label>Nombre Esposa</label>
              <fg-input
                class="custom-input"
                type="text"
                v-model="membresia.NombreEsposa"
              ></fg-input>
            </div>
            <div class="col-md-4">
              <label>Domicilio</label>
              <fg-input
                class="custom-input"
                type="text"
                v-model="membresia.Domicilio"
              ></fg-input>
            </div>
          </div>

          <div class="row">
            <div id="ciudades" class="col-md-6">
              <label>Ciudad</label>
              <multiselect
                class="custom-multiselect"
                v-model="membresia.CiudadId"
                :options="filteredMunicipios"
                :searchable="true"
                :close-on-select="true"
                :clear-on-select="true"
                :allow-empty="false"
                placeholder="Seleccione una opción"
                :show-labels="false"
                label="nombre"
                track-by="id"
                selectLabel=""
                @input="filterMunicipios"
              ></multiselect>
            </div>
            <div id="departamentos" class="col-md-6">
              <label>Departamento</label>
              <multiselect
                class="custom-multiselect"
                v-model="membresia.DepartamentId"
                :options="filteredDepartamentos"
                :searchable="true"
                :close-on-select="true"
                :clear-on-select="true"
                :allow-empty="false"
                placeholder="Seleccione una opción"
                :show-labels="false"
                label="nombre"
                track-by="id"
                selectLabel=""
                @input="filterDepartamentos"
              ></multiselect>
            </div>
          </div>

          <div class="row">
            <div class="col-md-4">
              <label>Teléfono Fijo</label>
              <fg-input
                class="custom-input"
                type="text"
                v-model="membresia.TelefonoFijo"
              ></fg-input>
            </div>
            <div class="col-md-4">
              <label>Correo Electrónico</label>
              <fg-input
                class="custom-input"
                type="email"
                v-model="membresia.CorreoElectronico"
              ></fg-input>
            </div>
            <div class="col-md-4">
              <label>Celular</label>
              <fg-input
                class="custom-input"
                type="text"
                v-model="membresia.Celular"
              ></fg-input>
            </div>
          </div>

          <div class="row">
            <div class="col-md-4">
              <label>IHSS</label>
              <fg-input
                class="custom-input"
                type="text"
                v-model="membresia.IHSS"
              ></fg-input>
            </div>
            <div class="col-md-4">
              <label>Tipo de Sangre</label>
              <multiselect
                class="custom-multiselect"
                v-model="membresia.TipoSangre"
                :options="tipoSangreOptions"
                :searchable="false"
                :close-on-select="true"
                :clear-on-select="false"
                :allow-empty="false"
                placeholder="Seleccione una opción"
                :show-labels="false"
              ></multiselect>
            </div>
            <div class="col-md-4">
              <label>Fecha de Nacimiento</label>
              <fg-input
                class="custom-input"
                type="date"
                v-model="membresia.FechaNacimiento"
              ></fg-input>
          </div>
          </div>


          <!-- Tabla Datos Profesionales -->
          <h5 style="background-color: #f1f1f1; padding: 10px; text-align: center; color: #252422;">
            <i class="ti-briefcase"></i> Datos Profesionales
          </h5>
          <div class="row">
            <div class="col-md-6">
              <label>Profesion u oficio</label>
              <fg-input class="custom-input" type="text" v-model="membresia.Profesion"></fg-input>
            </div>
            <div class="col-md-6">
              <label>Escuela Primaria</label>
              <fg-input
                class="custom-input"
                type="text"
                v-model="membresia.Primaria"
              ></fg-input>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6">
              <label>Secundaria</label>
              <fg-input
                class="custom-input"
                type="text"
                v-model="membresia.Secundaria"
              ></fg-input>
            </div>
            <div class="col-md-6">
              <label>Superior</label>
              <fg-input
                class="custom-input"
                type="text"
                v-model="membresia.Superior"
              ></fg-input>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6">
              <label>Otros cursos recibidos</label>
              <fg-input
                class="custom-input"
                type="text"
                v-model="membresia.Cursos"
              ></fg-input>
            </div>
            <div class="col-md-6">
              <label>Cargos Publicos</label>
              <fg-input
                class="custom-input"
                type="text"
                v-model="membresia.CargosPublicos"
              ></fg-input>
            </div>
          </div>

          <!-- Tabla Datos Empresariales -->
          <h5 style="background-color: #f1f1f1; padding: 10px; text-align: center; color: #252422;">
            <i class="ti-stats-up"></i> Datos Empresariales
          </h5>
          <div class="row">
            <div class="col-md-4">
              <label>Negocio Propio</label>
              <multiselect
                class="custom-multiselect"
                v-model="membresia.NegocioPropio"
                :options="negocioPropioOptions"
                :searchable="false"
                :close-on-select="true"
                :clear-on-select="false"
                :allow-empty="false"
                placeholder="Seleccione una opción"
                :show-labels="false"
              ></multiselect>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6">
              <label>Empresa</label>
              <fg-input
                class="custom-input"
                type="text"
                v-model="membresia.Empresa"
              ></fg-input>
            </div>
            <div class="col-md-6">
              <label>Direccion</label>
              <fg-input
                class="custom-input"
                type="text"
                v-model="membresia.DireccionEmpresa"
              ></fg-input>
          </div>
          </div>
          <div class="row">
            <div class="col-md-6">
              <label>Cargo</label>
              <fg-input
                class="custom-input"
                type="text"
                v-model="membresia.Cargo"
              ></fg-input>
            </div>
            <div class="col-md-6">
              <label>Actividad Principal</label>
              <fg-input
                class="custom-input"
                type="text"
                v-model="membresia.ActividadPrincipal"
              ></fg-input>
          </div>
          </div>
          <div class="row">
            <div class="col-md-4">
              <label>Telefono</label>
              <fg-input
                class="custom-input"
                type="text"
                v-model="membresia.TelefonoEmpresa"
              ></fg-input>
            </div>
            <div class="col-md-4">
              <label>Fax</label>
              <fg-input
                class="custom-input"
                type="text"
                v-model="membresia.FaxEmpresa"
              ></fg-input>
          </div>
          <div class="col-md-4">
              <label>Email</label>
              <fg-input
                class="custom-input"
                type="text"
                v-model="membresia.EmailEmpresa"
              ></fg-input>
          </div>
          </div>
          <!-- Tabla Datos Espirituales -->
          <h5 style="background-color: #f1f1f1; padding: 10px; text-align: center; color: #252422;">
            <i class="ti-plus"></i> Datos Espirituales
          </h5>
          <div class="row">
            <div class="col-md-4">
              <label>Fecha de Ingreso</label>
              <fg-input class="custom-input" type="date" v-model="membresia.FechaIngreso"></fg-input>
            </div>
            <div class="col-md-8">
              <label>Cargos en MIC LOS GRANADOS ROCA FUERTE</label>
              <fg-input
                class="custom-input"
                type="text"
                v-model="membresia.Cargos"
              ></fg-input>
          </div>
          </div>
          <div class="row">
            <div class="col-md-6">
              <label>Cargo Actual</label>
              <fg-input
                class="custom-input"
                type="text"
                v-model="membresia.CargoActual"
              ></fg-input>
            </div>

            <div class="col-md-6">
              <label>Cargo Pasado</label>
              <fg-input
                class="custom-input"
                type="text"
                v-model="membresia.CargoPasado"
              ></fg-input>
          </div>
          </div>

          <div class="row">
            <div class="col-md-4">
              <label>Miembro Activo</label>
              <multiselect
                class="custom-multiselect"
                v-model="membresia.MiembroActivo"
                :options="miembroActivoOptions"
                :searchable="false"
                :close-on-select="true"
                :clear-on-select="false"
                :allow-empty="false"
                placeholder="Seleccione una opción"
                :show-labels="false"
              ></multiselect>
            </div>

            <div class="col-md-4">
              <label>Ha asistido a un Seminario</label>
              <multiselect
                class="custom-multiselect"
                v-model="membresia.Seminario"
                :options="seminarioOptions"
                :searchable="false"
                :close-on-select="true"
                :clear-on-select="false"
                :allow-empty="false"
                placeholder="Seleccione una opción"
                :show-labels="false"
              ></multiselect>
            </div>
            <div class="col-md-4">
              <label>Quien lo invito</label>
              <fg-input
                class="custom-input"
                type="text"
                v-model="membresia.MiembroInvitador"
              ></fg-input>
          </div>
          </div>

          <div class="row">
            <div class="col-md-6">
              <label>Iglesia a la que pertenecía anteriormente</label>
              <fg-input
                class="custom-input"
                type="text"
                v-model="membresia.IglesiaAnterior"
              ></fg-input>
          </div>
          </div>

          <div class="row">
            <div class="col-md-4">
              <label>Pastor o Sacerdote encargado</label>
              <fg-input
                class="custom-input"
                type="text"
                v-model="membresia.PastorOSacerdote"
              ></fg-input>
            </div>
            <div class="col-md-4">
              <label>Celular</label>
              <fg-input
                class="custom-input"
                type="text"
                v-model="membresia.CelularPastor"
              ></fg-input>
          </div>
          <div class="col-md-4">
              <label>Correo</label>
              <fg-input
                class="custom-input"
                type="text"
                v-model="membresia.CorreoPastor"
              ></fg-input>
          </div>
        </div>

        <div class="row">
            <div class="col-md-8">
              <label>Cargos en su Iglesia</label>
              <fg-input
                class="custom-input"
                type="text"
                v-model="membresia.CargosIglesiaActual"
              ></fg-input>
            </div>
            <div class="col-md-4">
              <label>Tiempo de Servicio</label>
              <fg-input
                class="custom-input"
                type="text"
                v-model="membresia.TiempoIglesiaActual"
              ></fg-input>
          </div>
        </div>

        <div class="row">
            <div class="col-md-6">
              <label>Cargo Actual</label>
              <fg-input
                class="custom-input"
                type="text"
                v-model="membresia.CargoActualIglesiaActual"
              ></fg-input>
            </div>
            <div class="col-md-6">
              <label>Cargo Pasado</label>
              <fg-input
                class="custom-input"
                type="text"
                v-model="membresia.CargoPasadoIglesiaActual"
              ></fg-input>
          </div>
        </div>

        <div class="row">
            <div class="col-md-6">
              <label>Que tipo de Testimonio tiene</label>
              <multiselect
                class="custom-multiselect"
                v-model="membresia.Testimonio"
                :options="testimonioOptions"
                :searchable="false"
                :close-on-select="true"
                :clear-on-select="false"
                :allow-empty="false"
                placeholder="Seleccione una opción"
                label="nombre"
                track-by="id"
                selectLabel=""
                :show-labels="false"
              ></multiselect>
            </div>

            <div class="col-md-6">
              <label>Dones Esprirituales que ha recibido</label>
              <multiselect
                class="custom-multiselect"
                v-model="membresia.Dones"
                :options="donesOptions"
                :searchable="false"
                :close-on-select="true"
                :clear-on-select="false"
                :allow-empty="false"
                placeholder="Seleccione una opción"
                label="nombre"
                track-by="id"
                selectLabel=""
                :show-labels="false"
              ></multiselect>
            </div>
          </div>

          <div class="text-center">
            <p-button class="btn-lg" style="margin: 40px;" type="info" round @click.native.prevent="crearMembresia">
              Enviar Inscripción
            </p-button>
          </div>
          <div class="clearfix"></div>
        </form>
      </div>
    </card>
  </div>
</template>
<script>
import Multiselect from 'vue-multiselect';
import 'vue-multiselect/dist/vue-multiselect.min.css';
import membresiasService from '@/services/membresias_service';

export default {
  components: { Multiselect },
  data() {
    return {
      membresia: {
        //Datos Membresia
        ZonaMic: null,
        MunicipioIdLocalidad: null,
        DepartamentIdLocalidad: null,
        Zona: null,
        NumeroMembresia: "",
        NumeroEmpleadoHiguertropic: "",

        //Datos Generales
        NombreCompleto: "",
        NumeroIdentidad: "",
        EstadoCivil: null,
        NombreEsposa: "",
        Domicilio: "",
        CiudadId: null,
        DepartamentId: null,
        TelefonoFijo: "",
        CorreoElectronico: null,
        Celular: "",
        IHSS: "",
        TipoSangre: null,
        FechaNacimiento: null,

        //Datos Profesionales
        Profesion: "",
        Primaria: "",
        Secundaria: "",
        Superior: "",
        Cursos: "",
        CargosPublicos: "",

        //Datos Empresariales
        NegocioPropio: null,
        Empresa: "",
        DireccionEmpresa: "",
        Cargo: "",
        ActividadPrincipal: "",
        TelefonoEmpresa: "",
        FaxEmpresa: "",
        EmailEmpresa: null,

        //Datos Espirituales
        FechaIngreso: null,
        Cargos: "",
        CargoActual: "",
        CargoPasado: "",
        MiembroActivo: null,
        Seminario: null,
        MiembroInvitador: "",
        IglesiaAnterior: "",
        PastorOSacerdote: "",
        CelularPastor: "",
        CorreoPastor: null,
        CargosIglesiaActual: "",
        TiempoIglesiaActual: "",
        CargoActualIglesiaActual: "",
        CargoPasadoIglesiaActual: "",
        Testimonio: null,
        Dones: null,
      },

       //Opciones de Multiselect
      zonaMicOptions: [
        { id: 1, nombre: 'Central' },
        { id: 2, nombre: 'Norte' },
        { id: 3, nombre: 'Sur' },
        { id: 4, nombre: 'Este' },
        { id: 5, nombre: 'Oeste' },
      ],
      // localidadOptions: [
      // { id: 1, nombre: 'Loma Limpia' },
      //   { id: 2, nombre: 'San Esteban' },
      //   { id: 3, nombre: 'El Ocote' },
      //   { id: 4, nombre: 'El Mico' },
      //   { id: 5, nombre: 'San Agustin' },
      //   { id: 6, nombre: 'Coronado' },
      //   { id: 7, nombre: 'El Aguacate' },
      //   { id: 8, nombre: 'Queseras' },
      //   { id: 9, nombre: 'Culuco' },
      //   { id: 10, nombre: 'La Venta' },

      // ],
      zonaOptions: [
        { id: 1, nombre: 'Norte' },
        { id: 2, nombre: 'Sur' },
        { id: 3, nombre: 'Este' },
        { id: 4, nombre: 'Oeste' },
      ],
      estadoCivilOptions: ['Soltero' ,'Casado', 'Divorciado', 'Viudo', 'Unión Libre'],
      tipoSangreOptions: ['A', 'B', 'AB', 'O'],
      negocioPropioOptions: ['Si', 'No'],
      miembroActivoOptions: ['Si', 'No'],
      seminarioOptions: ['Si', 'No'],
      testimonioOptions: [
        { id: 1, nombre: 'Personal' },
        { id: 2, nombre: 'En Pareja o con Hijos' },
        { id: 3, nombre: 'Alcoholismo' },
        { id: 4, nombre: 'Cambio de Vida' },
        { id: 5, nombre: 'Perdon' },
        { id: 6, nombre: 'Drogadiccion' },
        { id: 7, nombre: 'Prosperidad' },
        { id: 8, nombre: 'Otros' },
      ],
      donesOptions: [
        { id: 1, nombre: 'Sanidad' },
        { id: 2, nombre: 'Profecia' },
        { id: 3, nombre: 'Servidor' },
        { id: 4, nombre: 'Milagros' },
        { id: 5, nombre: 'Lenguas' },
        { id: 6, nombre: 'Otros' },
      ],

      //Inicializar variables de opciones
      municipios: [],
      departamentos: [],
      localidades: [],
      filteredMunicipios: [],
      filteredDepartamentos: [],
      filteredLocalidades: [],
      //Manejo de errores
      errores: {},
    };
  },
  methods: {
    mapNegocioPropio(value) {
      return value === 'Si' ? 1 : 0;
    },
    mapMiembroActivo(value) {
      return value === 'Si' ? 1 : 0;
    },
    mapSeminario(value) {
      return value === 'Si' ? 1 : 0;
    },

    async crearMembresia() {
      const negocioPropioValue = this.mapNegocioPropio(this.membresia.NegocioPropio);
      const miembroActivoValue = this.mapMiembroActivo(this.membresia.MiembroActivo);
      const seminarioValue = this.mapSeminario(this.membresia.Seminario);
      this.errores = {}; // Reset errores to ensure it's clean before adding new errors

    try {
      const response = await membresiasService.postMembresias({
      Ubicacion:{
        NumeroMembresia: this.membresia.NumeroMembresia,
        NumeroEmpleadoHiguertropic: this.membresia.NumeroEmpleadoHiguertropic,
        ZonaMic: this.membresia.ZonaMic,
        Municipio: this.membresia.MunicipioId,
        Departamento: this.membresia.DepartamentoId,
        Localidad: this.membresia.LocalidadId,
        Zona: this.membresia.Zona,

      },
      DatosGenerales:{
        NombreCompleto: this.membresia.NombreCompleto,
        NumeroIdentidad: this.membresia.NumeroIdentidad,
        EstadoCivil: this.membresia.EstadoCivil,
        NombreEsposa: this.membresia.NombreEsposa,
        Domicilio: this.membresia.Domicilio,
        Ciudad: this.membresia.CiudadId,
        Departamento: this.membresia.DepartamentId,
        TelefonoFijo: this.membresia.TelefonoFijo,
        CorreoElectronico: this.membresia.CorreoElectronico,
        Celular: this.membresia.Celular,
        IHSS: this.membresia.IHSS,
        TipoSangre: this.membresia.TipoSangre,
        FechaNacimiento: this.membresia.FechaNacimiento,

      },
      DatosProfesionales:{
        Profesion: this.membresia.Profesion,
        Primaria: this.membresia.Primaria,
        Secundaria: this.membresia.Secundaria,
        Superior: this.membresia.Superior,
        Cursos: this.membresia.Cursos,
        CargosPublicos: this.membresia.CargosPublicos,

      },
      DatosEmpresariales:{
        NegocioPropio: negocioPropioValue,
        NombreEmpresa: this.membresia.Empresa,
        Direccion: this.membresia.DireccionEmpresa,
        Cargo: this.membresia.Cargo,
        ActividadPrincipal: this.membresia.ActividadPrincipal,
        Telefono: this.membresia.TelefonoEmpresa,
        Fax: this.membresia.FaxEmpresa,
        Email: this.membresia.EmailEmpresa,

      },
      DatosEspirituales:{
        FechaIngreso: this.membresia.FechaIngreso,
        Cargos: this.membresia.Cargos,
        CargoActual: this.membresia.CargoActual,
        CargoPasado: this.membresia.CargoPasado,
        MiembroActivo: miembroActivoValue,
        Seminario: seminarioValue,
        MiembroInvitador: this.membresia.MiembroInvitador,
        Nombre: this.membresia.IglesiaAnterior,
        PastorOSacerdote: this.membresia.PastorOSacerdote,
        Celular: this.membresia.CelularPastor,
        CorreoElectronico: this.membresia.CorreoPastor,
        CargosIA: this.membresia.CargosIglesiaActual,
        TiempoServicio: this.membresia.TiempoIglesiaActual,
        CargoActualIA: this.membresia.CargoActualIglesiaActual,
        CargoPasadoIA: this.membresia.CargoPasadoIglesiaActual,
        Test: this.membresia.Testimonio,
        Don: this.membresia.Dones,

      },
});


    this.$toast.success('Membresia enviado con éxito', {
      position: "top-right",
      timeout: 5000,
    });
    this.resetForm(); // Reset form fields after successful submission
    } catch (error) {
    console.error('Error de envío', error);

    this.$toast.error('Error al enviar el membresia', {
      position: "top-right",
      timeout: 5000,
    });
    }
    },

    resetForm() {
      this.membresia = {
        ZonaMic: "",
        MunicipioId: "",
        DepartamentoId: "",
        LocalidadId: "",
        Zona: "",
        NombreCompleto: "",
        Profesion: "",
        NegocioPropio: "",
        FechaIngreso: "",
      };
      this.errores = {};
    },

    fetchCiudades() {
      membresiasService.getCiudades()
        .then(response => {
          this.municipios = response.data.data.map(ciudad => ({
            id: ciudad.id,
            nombre: ciudad.attributes.Nombre
          }));
          this.filteredMunicipios = this.municipios;
        })
        .catch(error => {
          console.error('Error al obtener municipios', error);
        });
    },

    fetchDepartamentos() {
      membresiasService.getDepartamentos()
        .then(response => {
          this.departamentos = response.data.data.map(departamento => ({
            id: departamento.id,
            nombre: departamento.attributes.Nombre
          }));
          this.filteredDepartamentos = this.departamentos;
        })
        .catch(error => {
          console.error('Error al obtener departamentos', error);
        });
    },

    fetchLocalidades() {
      membresiasService.getLocalidades()
        .then(response => {
          this.localidades = response.data.data.map(localidad => ({
            id: localidad.id,
            nombre: localidad.attributes.Nombre
          }));
          this.filteredLocalidades = this.localidades;
        })
        .catch(error => {
          console.error('Error al obtener localidades', error);
        });
    },
  },
  created() {
    this.fetchCiudades();
    this.fetchDepartamentos();
    this.fetchLocalidades();
  }

};
</script>

<style scoped>
.custom-input {
  border: solid 1px #e4e7ea;
  margin-bottom: 25px;
}
.custom-multiselect {
  font-size: 14px;
  color: #66615b;
  margin-bottom: 25px;
}
::v-deep label {
  color: #252422 !important;
}
</style>
