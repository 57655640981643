<template>
  <div>
    <card class="card" title="Formato Inscripción Solicitud de Empleo" subTitle="Solicito muy repestuosamente a la GERENCIA GENERAL de HIGUERTROPIC
HONDURAS, S. DE. R.L. una oportunidad en las plazas de trabajo disponibles,
para lo cual proporciono los siguientes datos:">
      <div v-if="errores && Object.keys(errores).length" class="alert alert-warning" ref="erroresContainer">
        <p v-for="(mensaje, campo) in errores" :key="campo">{{ campo }} inválido: {{ mensaje }}</p>
      </div>
      <!-- Datos Generales -->
      <h5 style="background-color: #f1f1f1; padding: 10px; text-align: center; color: #252422;">
            <i class="ti-id-badge"></i> Datos Generales
          </h5>
      <div>
        <form @submit.prevent="actualizarSolicitudEmpleo">

          <div class="row">
            <div class="col-md-6">
              <label>Numero de Membresia MIC</label>
              <fg-input class="custom-input"
              type="text"
              v-model="solicitud.NumeroMembresia">
            </fg-input>
            </div>
            <div class="col-md-6" style="text-align: right;">
              <p-button style="margin-right: 20px;"><i class="ti-upload"></i>  Subir una imagen</p-button>
              <img src="@/assets/img/imagen-perfil.png" alt="Subir imagen" width="100" style="margin-right: 40px; opacity: 0.8;">
            </div>
          </div>

          <!--Datos Generales-->
          <div class="row">
            <div class="col-md-8">
              <label>Lugar</label>
              <fg-input class="custom-input"
              type="text"
              v-model="solicitud.Lugar">
            </fg-input>
            </div>
            <div class="col-md-4">
              <label>Fecha</label>
              <fg-input class="custom-input"
              type="date"
              v-model="solicitud.Fecha">
            </fg-input>
            </div>
          </div>

          <div class="row">
            <div class="col-md-4">
              <label>Municipio</label><span class="required-asterisk">*</span>
              <multiselect
                class="custom-multiselect"
                v-model="solicitud.MunicipioId"
                :options="filteredMunicipios"
                :searchable="true"
                :close-on-select="true"
                :clear-on-select="true"
                :allow-empty="false"
                placeholder="Seleccione una opción"
                :show-labels="false"
                label="nombre"
                track-by="id"
                selectLabel=""
                @input="filterCiudades"
              ></multiselect>
            </div>
            <div class="col-md-4">
              <label>Departamento</label><span class="required-asterisk">*</span>
              <multiselect
                class="custom-multiselect"
                v-model="solicitud.DepartamentoId"
                :options="filteredDepartamentos"
                :searchable="true"
                :close-on-select="true"
                :clear-on-select="true"
                :allow-empty="false"
                placeholder="Seleccione una opción"
                :show-labels="false"
                label="nombre"
                track-by="id"
                selectLabel=""
                @input="filterDepartamentos"
              ></multiselect>
            </div>
            <div class="col-md-4">
              <label>Zona</label>
              <multiselect
                class="custom-multiselect"
                v-model="solicitud.Zona"
                :options="zonaOptions"
                :searchable="false"
                :close-on-select="true"
                :clear-on-select="false"
                :allow-empty="false"
                placeholder="Seleccione una opción"
                :show-labels="false"
              ></multiselect>
            </div>
          </div>

          <div class="row">
            <div class="col-md-6">
              <label>Nombre</label>
              <fg-input class="custom-input"
              type="text"
              v-model="solicitud.Nombre">
            </fg-input>
            </div>
            <div class="col-md-6">
              <label>Identidad</label>
              <fg-input class="custom-input"
              type="text"
              v-model="solicitud.Identidad">
            </fg-input>
            </div>
          </div>

          <div class="row">
            <div class="col-md-6">
              <label>Puesto que solicita</label>
              <fg-input class="custom-input"
              type="text"
              v-model="solicitud.PuestoSolicita">
            </fg-input>
            </div>
            <div class="col-md-6">
              <label>Sueldo Mensual</label>
              <fg-input class="custom-input"
              type="text"
              v-model="solicitud.SueldoMensual">
            </fg-input>
            </div>
          </div>

          <div class="row">
            <div class="col-md-6">
              <label>Lugar de Nacimiento</label>
              <fg-input class="custom-input"
              type="text"
              v-model="solicitud.LugarNacimiento">
            </fg-input>
            </div>
            <div class="col-md-6">
              <label>Fecha de Nacimiento</label>
              <fg-input class="custom-input"
              type="date"
              v-model="solicitud.FechaNacimiento">
            </fg-input>
            </div>
          </div>

          <div class="row">
            <div class="col-md-4">
              <label>IHSS</label>
              <fg-input class="custom-input"
              type="text"
              v-model="solicitud.IHSS">
            </fg-input>
            </div>
            <div class="col-md-4">
              <label>Estado Civil</label>
              <multiselect
                class="custom-multiselect"
                v-model="solicitud.EstadoCivil"
                :options="estadoCivilOptions"
                :searchable="false"
                :close-on-select="true"
                :clear-on-select="false"
                :allow-empty="false"
                placeholder="Seleccione una opción"
                :show-labels="false"
              ></multiselect>
            </div>
            <div class="col-md-4">
              <label>Tipo Sangre</label>
              <multiselect
                class="custom-multiselect"
                v-model="solicitud.TipoSangre"
                :options="tipoSangreOptions"
                :searchable="false"
                :close-on-select="true"
                :clear-on-select="false"
                :allow-empty="false"
                placeholder="Seleccione una opción"
                :show-labels="false"
              ></multiselect>
            </div>
          </div>

          <div class="row">
            <div class="col-md-4">
              <label>Telefono Fijo</label>
              <fg-input class="custom-input"
              type="text"
              v-model="solicitud.TelefonoFijo">
            </fg-input>
            </div>
            <div class="col-md-4">
              <label>Celular</label>
              <fg-input class="custom-input"
              type="text"
              v-model="solicitud.Celular">
            </fg-input>
            </div>
            <div class="col-md-4">
              <label>Correo Electrónico</label>
              <fg-input class="custom-input"
              type="email"
              v-model="solicitud.CorreoElectronico">
            </fg-input>
            </div>
          </div>

          <div class="row">
            <div class="col-md-4">
              <label>Direccion</label>
              <fg-input class="custom-input"
              type="text"
              v-model="solicitud.Direccion">
            </fg-input>
            </div>
            <div class="col-md-4">
              <label>Ciudad</label><span class="required-asterisk">*</span>
              <multiselect
                class="custom-multiselect"
                v-model="solicitud.CiudadId"
                :options="filteredMunicipios"
                :searchable="true"
                :close-on-select="true"
                :clear-on-select="true"
                :allow-empty="false"
                placeholder="Seleccione una opción"
                :show-labels="false"
                label="nombre"
                track-by="id"
                selectLabel=""
                @input="filterMunicipios"
              ></multiselect>
            </div>
            <div class="col-md-4">
              <label>Departamento</label><span class="required-asterisk">*</span>
              <multiselect
                class="custom-multiselect"
                v-model="solicitud.DepartamentId"
                :options="filteredDepartamentos"
                :searchable="true"
                :close-on-select="true"
                :clear-on-select="true"
                :allow-empty="false"
                placeholder="Seleccione una opción"
                :show-labels="false"
                label="nombre"
                track-by="id"
                selectLabel=""
                @input="filterDepartamentos"
              ></multiselect>
            </div>
          </div>

          <div class="row">
            <div class="col-md-4">
              <label>Casa Propia</label>
              <multiselect
                class="custom-multiselect"
                v-model="solicitud.CasaPropia"
                :options="casaPropiaOptions"
                :searchable="false"
                :close-on-select="true"
                :clear-on-select="false"
                :allow-empty="false"
                placeholder="Seleccione una opción"
                :show-labels="false"
              ></multiselect>
            </div>
            <div class="col-md-4">
              <label>Valor Casa Lps.</label>
              <fg-input class="custom-input"
              type="text"
              v-model="solicitud.ValorCasa">
            </fg-input>
            </div>
            <div class="col-md-4">
              <label>Alquiler Casa Lps.</label>
              <fg-input class="custom-input"
              type="text"
              v-model="solicitud.AlquilerCasa">
            </fg-input>
            </div>
          </div>

          <div class="row">
            <div class="col-md-4">
              <label>Posee Vehiculo</label>
              <multiselect
                class="custom-multiselect"
                v-model="solicitud.PoseeVehiculo"
                :options="poseeVehiculoOptions"
                :searchable="false"
                :close-on-select="true"
                :clear-on-select="false"
                :allow-empty="false"
                placeholder="Seleccione una opción"
                :show-labels="false"
              ></multiselect>
            </div>
            <div class="col-md-8">
              <label>Tipo Vehiculo</label>
              <fg-input class="custom-input"
              type="text"
              v-model="solicitud.TipoVehiculo">
            </fg-input>
            </div>
          </div>

          <div class="row">
            <div class="col-md-4">
              <label>Marca</label>
              <fg-input class="custom-input"
              v-model="solicitud.MarcaVehiculo">
            </fg-input>
            </div>
            <div class="col-md-4">
              <label>Placa</label>
              <fg-input class="custom-input"
              v-model="solicitud.PlacaVehiculo">
            </fg-input>
            </div>
            <div class="col-md-4">
              <label>Año</label>
              <fg-input class="custom-input"
              v-model="solicitud.AnioVehiculo">
            </fg-input>
            </div>
          </div>

          <div class="row">
            <div class="col-md-4">
              <label>Tipo Licencia</label>
              <fg-input class="custom-input"
              v-model="solicitud.TipoLicencia">
            </fg-input>
            </div>
            <div class="col-md-4">
              <label>Vencimiento</label>
              <fg-input class="custom-input"
              v-model="solicitud.VencimientoLicencia">
            </fg-input>
            </div>
            <div class="col-md-4">
              <label>Otro tipo de Vehiculo</label>
              <fg-input class="custom-input"
              v-model="solicitud.OtroVehiculo">
            </fg-input>
            </div>
          </div>

          <div class="row">
            <div class="col-md-4">
              <label>Cuantos Trabajan en su Hogar</label>
              <fg-input class="custom-input"
              type="number"
              v-model="solicitud.CuantosTrabajan">
            </fg-input>
            </div>
            <div class="col-md-4">
              <label>Su salud es</label>
              <multiselect
                class="custom-multiselect"
                v-model="solicitud.Salud"
                :options="saludOptions"
                :searchable="false"
                :close-on-select="true"
                :clear-on-select="false"
                :allow-empty="false"
                placeholder="Seleccione una opción"
                :show-labels="false"
              ></multiselect>
            </div>
            <div class="col-md-4">
              <label>Estudia por las noches</label>
              <multiselect
                class="custom-multiselect"
                v-model="solicitud.EstudiaNoches"
                :options="estudiaNochesOptions"
                :searchable="false"
                :close-on-select="true"
                :clear-on-select="false"
                :allow-empty="false"
                placeholder="Seleccione una opción"
                :show-labels="false"
              ></multiselect>
            </div>
          </div>

          <div class="row">
            <div class="col-md-6">
              <label>Nombre de sus Padres</label>
              <fg-input class="custom-input"
              v-model="solicitud.NombrePadre">
            </fg-input>
            </div>
            <div class="col-md-6">
              <label>Direccion Padres</label>
              <fg-input class="custom-input"
              v-model="solicitud.DireccionPadres">
            </fg-input>
            </div>
          </div>

          <div class="row">
            <div class="col-md-8">
              <label>Nombre de su Conyugue</label>
              <fg-input class="custom-input"
              v-model="solicitud.Conyugue">
            </fg-input>
            </div>
            <div class="col-md-4">
              <label>Numero de Hijos</label>
              <fg-input class="custom-input"
              v-model="solicitud.Hijos">
            </fg-input>
            </div>
          </div>

          <div class="row">
            <div class="col-md-4">
              <label>Telefono Fijo</label>
              <fg-input class="custom-input"
              type="text"
              v-model="solicitud.CTelefonoFijo">
            </fg-input>
            </div>
            <div class="col-md-4">
              <label>Celular</label>
              <fg-input class="custom-input"
              type="text"
              v-model="solicitud.CCelular">
            </fg-input>
            </div>
            <div class="col-md-4">
              <label>Correo Electrónico</label>
              <fg-input class="custom-input"
              type="email"
              v-model="solicitud.CCorreoElectronico">
            </fg-input>
            </div>
          </div>

          <!-- Nivel Academico -->
      <h5 style="background-color: #f1f1f1; padding: 10px; text-align: center; color: #252422;">
            <i class="ti-id-badge"></i> Nivel Academico
          </h5>
      <div></div>

          <div class="row">
            <div class="col-md-6">
              <label>Profesion u Oficio</label>
              <fg-input class="custom-input"
              type="text"
              v-model="solicitud.Profesion">
            </fg-input>
            </div>
          </div>

          <div class="row">
            <div class="col-md-6">
              <label>Nombre de la Escuela</label>
              <fg-input class="custom-input"
              type="text"
              v-model="solicitud.Escuela">
            </fg-input>
            </div>
            <div class="col-md-6">
              <label>Grado de la Escuela</label>
              <fg-input class="custom-input"
              type="text"
              v-model="solicitud.EscuelaGrado">
            </fg-input>
            </div>
          </div>

          <div class="row">
            <div class="col-md-6">
              <label>Nombre del Instituto</label>
              <fg-input class="custom-input"
              type="text"
              v-model="solicitud.Instituto">
            </fg-input>
            </div>
            <div class="col-md-2">
              <label>Grado del Instituto</label>
              <fg-input class="custom-input"
              type="text"
              v-model="solicitud.InstitutoGrado">
            </fg-input>
            </div>
            <div class="col-md-4">
              <label>Titulo de Secundaria Obtenido</label>
              <fg-input class="custom-input"
              v-model="solicitud.TituloSecundaria">
            </fg-input>
            </div>
          </div>

          <div class="row">
            <div class="col-md-6">
              <label>Nombre de la Universidad</label>
              <fg-input class="custom-input"
              type="text"
              v-model="solicitud.Universidad">
            </fg-input>
            </div>
            <div class="col-md-6">
              <label>Titulo de Universidad</label>
              <fg-input class="custom-input"
              type="text"
              v-model="solicitud.TituloUniversidad">
            </fg-input>
            </div>
          </div>

          <div class="row">
            <div class="col-md-6">
              <label>Cursos Especiales</label>
              <fg-input class="custom-input"
              type="text"
              v-model="solicitud.Cursos">
            </fg-input>
            </div>
            <div class="col-md-6">
              <label>Equipos o Maquinas que puede usar</label>
              <fg-input class="custom-input"
              type="text"
              v-model="solicitud.Equipos">
            </fg-input>
            </div>
          </div>

          <div class="row">
            <div class="col-md-4">
              <label>Servicio Militar o Policial</label>
              <multiselect
                class="custom-multiselect"
                v-model="solicitud.EstudiaNoches"
                :options="estudiaNochesOptions"
                :searchable="false"
                :close-on-select="true"
                :clear-on-select="false"
                :allow-empty="false"
                placeholder="Seleccione una opción"
                :show-labels="false"
              ></multiselect>
            </div>
          </div>

          <div class="row">
            <div class="col-md-4">
              <label>Unidad a la que pertenecio</label>
              <fg-input class="custom-input"
              v-model="solicitud.UnidadSM">
            </fg-input>
            </div>
            <div class="col-md-4">
              <label>Lugar</label>
              <fg-input class="custom-input"
              v-model="solicitud.LugarSM">
            </fg-input>
            </div>
            <div class="col-md-4">
              <label>Periodo de Servicio</label>
              <fg-input class="custom-input"
              v-model="solicitud.PeriodoSM">
            </fg-input>
            </div>
          </div>

          <div class="row">
            <div class="col-md-4">
              <label>Nombre del Jefe inmediato</label>
              <fg-input class="custom-input"
              v-model="solicitud.ComandanteSM">
            </fg-input>
            </div>
            <div class="col-md-4">
              <label>Grado o Rango alcanzado</label>
              <fg-input class="custom-input"
              v-model="solicitud.RangoSM">
            </fg-input>
            </div>
            <div class="col-md-4">
              <label>Numero de registro Militar o Policial</label>
              <fg-input class="custom-input"
              v-model="solicitud.RegistroSM">
            </fg-input>
            </div>
          </div>

          <!-- Empleos Anteriores -->
      <h5 style="background-color: #f1f1f1; padding: 10px; text-align: center; color: #252422;">
            <i class="ti-id-badge"></i> Empleos Anteriores
          </h5>
      <div></div>

      <div class="row">
            <div class="col-md-4">
              <label>Negocio Propio</label>
              <multiselect
                class="custom-multiselect"
                v-model="solicitud.NegocioPropio"
                :options="negocioPropioOptions"
                :searchable="false"
                :close-on-select="true"
                :clear-on-select="false"
                :allow-empty="false"
                placeholder="Seleccione una opción"
                :show-labels="false"
              ></multiselect>
            </div>
          </div>

          <div class="row">
            <div class="col-md-6">
              <h4>Empresa 1</h4>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6">
              <label>Nombre</label>
              <fg-input class="custom-input"
              type="text"
              v-model="solicitud.NombreE1">
            </fg-input>
            </div>
            <div class="col-md-6">
              <label>Telefono</label>
              <fg-input class="custom-input"
              type="text"
              v-model="solicitud.TelefonoE1">
            </fg-input>
            </div>
          </div>

          <div class="row">
            <div class="col-md-4">
              <label>Sueldo Inicial</label>
              <fg-input class="custom-input"
              type="text"
              v-model="solicitud.SueldoIE1">
            </fg-input>
            </div>
            <div class="col-md-4">
              <label>Sueldo Final</label>
              <fg-input class="custom-input"
              type="text"
              v-model="solicitud.SueldoFE1">
            </fg-input>
            </div>
            <div class="col-md-4">
              <label>Fecha de Inicio</label>
              <fg-input class="custom-input"
              type="date"
              v-model="solicitud.FechaIE1">
            </fg-input>
            </div>
          </div>

          <div class="row">
            <div class="col-md-6">
              <label>Puesto </label>
              <fg-input class="custom-input"
              type="text"
              v-model="solicitud.PuestoE1">
            </fg-input>
            </div>
            <div class="col-md-6">
              <label>Obligaciones</label>
              <fg-input class="custom-input"
              type="text"
              v-model="solicitud.ObligacionesE1">
            </fg-input>
            </div>
          </div>

          <div class="row">
            <div class="col-md-6">
              <h4>Empresa 2</h4>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6">
              <label>Nombre</label>
              <fg-input class="custom-input"
              type="text"
              v-model="solicitud.NombreE2">
            </fg-input>
            </div>
            <div class="col-md-6">
              <label>Telefono</label>
              <fg-input class="custom-input"
              type="text"
              v-model="solicitud.TelefonoE2">
            </fg-input>
            </div>
          </div>

          <div class="row">
            <div class="col-md-4">
              <label>Sueldo Inicial</label>
              <fg-input class="custom-input"
              type="text"
              v-model="solicitud.SueldoIE2">
            </fg-input>
            </div>
            <div class="col-md-4">
              <label>Sueldo Final</label>
              <fg-input class="custom-input"
              type="text"
              v-model="solicitud.SueldoFE2">
            </fg-input>
            </div>
            <div class="col-md-4">
              <label>Fecha de Inicio</label>
              <fg-input class="custom-input"
              type="date"
              v-model="solicitud.FechaIE2">
            </fg-input>
            </div>
          </div>

          <div class="row">
            <div class="col-md-6">
              <label>Puesto </label>
              <fg-input class="custom-input"
              type="text"
              v-model="solicitud.PuestoE2">
            </fg-input>
            </div>
            <div class="col-md-6">
              <label>Obligaciones</label>
              <fg-input class="custom-input"
              type="text"
              v-model="solicitud.ObligacionesE2">
            </fg-input>
            </div>
          </div>

          <div class="row">
            <div class="col-md-6">
              <h4>Empresa 3</h4>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6">
              <label>Nombre</label>
              <fg-input class="custom-input"
              type="text"
              v-model="solicitud.NombreE3">
            </fg-input>
            </div>
            <div class="col-md-6">
              <label>Telefono</label>
              <fg-input class="custom-input"
              type="text"
              v-model="solicitud.TelefonoE3">
            </fg-input>
            </div>
          </div>

          <div class="row">
            <div class="col-md-4">
              <label>Sueldo Inicial</label>
              <fg-input class="custom-input"
              type="text"
              v-model="solicitud.SueldoIE3">
            </fg-input>
            </div>
            <div class="col-md-4">
              <label>Sueldo Final</label>
              <fg-input class="custom-input"
              type="text"
              v-model="solicitud.SueldoFE3">
            </fg-input>
            </div>
            <div class="col-md-4">
              <label>Fecha de Inicio</label>
              <fg-input class="custom-input"
              type="date"
              v-model="solicitud.FechaIE3">
            </fg-input>
            </div>
          </div>

          <div class="row">
            <div class="col-md-6">
              <label>Puesto </label>
              <fg-input class="custom-input"
              type="text"
              v-model="solicitud.PuestoE3">
            </fg-input>
            </div>
            <div class="col-md-6">
              <label>Obligaciones</label>
              <fg-input class="custom-input"
              type="text"
              v-model="solicitud.ObligacionesE3">
            </fg-input>
            </div>
          </div>

          <!-- Habilidades -->
      <h5 style="background-color: #f1f1f1; padding: 10px; text-align: center; color: #252422;">
            <i class="ti-id-badge"></i> Habilidades
          </h5>
      <div></div>
          <div class="row">
            <div class="col-md-12">
              <label>Habilidad 1</label>
              <fg-input class="custom-input"
              type="text"
              v-model="solicitud.Habilidad1">
            </fg-input>
            </div>
            <div class="col-md-12">
              <label>Habilidad 2</label>
              <fg-input class="custom-input"
              type="text"
              v-model="solicitud.Habilidad2">
            </fg-input>
            </div>
          </div>

          <!-- Personas Coocen -->
      <h5 style="background-color: #f1f1f1; padding: 10px; text-align: center; color: #252422;">
            <i class="ti-id-badge"></i> Personas que lo conocen
          </h5>
      <div></div>
          <div class="row">
            <div class="col-md-4">
              <h4>Persona 1</h4>
            </div>
          </div>
          <div class="row">
            <div class="col-md-4">
              <label>Nombre</label>
              <fg-input class="custom-input"
              type="text"
              v-model="solicitud.NombreP1">
            </fg-input>
            </div>
            <div class="col-md-4">
              <label>Telefono</label>
              <fg-input class="custom-input"
              type="text"
              v-model="solicitud.TelefonoP1">
            </fg-input>
            </div>
            <div class="col-md-4">
              <label>Direccion</label>
              <fg-input class="custom-input"
              v-model="solicitud.DireccionP1">
            </fg-input>
            </div>
          </div>

          <div class="row">
            <div class="col-md-4">
              <h4>Persona 2</h4>
            </div>
          </div>
          <div class="row">
            <div class="col-md-4">
              <label>Nombre</label>
              <fg-input class="custom-input"
              type="text"
              v-model="solicitud.NombreP2">
            </fg-input>
            </div>
            <div class="col-md-4">
              <label>Telefono</label>
              <fg-input class="custom-input"
              type="text"
              v-model="solicitud.TelefonoP2">
            </fg-input>
            </div>
            <div class="col-md-4">
              <label>Direccion</label>
              <fg-input class="custom-input"
              v-model="solicitud.DireccionP2">
            </fg-input>
            </div>
          </div>

          <div class="row">
            <div class="col-md-4">
              <h4>Persona 3</h4>
            </div>
          </div>
          <div class="row">
            <div class="col-md-4">
              <label>Nombre</label>
              <fg-input class="custom-input"
              type="text"
              v-model="solicitud.NombreP3">
            </fg-input>
            </div>
            <div class="col-md-4">
              <label>Telefono</label>
              <fg-input class="custom-input"
              type="text"
              v-model="solicitud.TelefonoP3">
            </fg-input>
            </div>
            <div class="col-md-4">
              <label>Direccion</label>
              <fg-input class="custom-input"
              v-model="solicitud.DireccionP3">
            </fg-input>
            </div>
          </div>

          <!-- Familiares -->
      <h5 style="background-color: #f1f1f1; padding: 10px; text-align: center; color: #252422;">
            <i class="ti-id-badge"></i> Familiares que no vivan con usted
          </h5>
      <div></div>
          <div class="row">
            <div class="col-md-4">
              <h4>Familiar 1</h4>
            </div>
          </div>
          <div class="row">
            <div class="col-md-4">
              <label>Nombre</label>
              <fg-input class="custom-input"
              type="text"
              v-model="solicitud.NombreF1">
            </fg-input>
            </div>
            <div class="col-md-4">
              <label>Telefono</label>
              <fg-input class="custom-input"
              type="text"
              v-model="solicitud.TelefonoF1">
            </fg-input>
            </div>
            <div class="col-md-4">
              <label>Direccion</label>
              <fg-input class="custom-input"
              v-model="solicitud.DireccionF1">
            </fg-input>
            </div>
          </div>

          <div class="row">
            <div class="col-md-4">
              <h4>Familiar 2</h4>
            </div>
          </div>
          <div class="row">
            <div class="col-md-4">
              <label>Nombre</label>
              <fg-input class="custom-input"
              type="text"
              v-model="solicitud.NombreF2">
            </fg-input>
            </div>
            <div class="col-md-4">
              <label>Telefono</label>
              <fg-input class="custom-input"
              type="text"
              v-model="solicitud.TelefonoF2">
            </fg-input>
            </div>
            <div class="col-md-4">
              <label>Direccion</label>
              <fg-input class="custom-input"
              v-model="solicitud.DireccionF2">
            </fg-input>
            </div>
          </div>

          <div class="row">
            <div class="col-md-4">
              <h4>Familiar 3</h4>
            </div>
          </div>
          <div class="row">
            <div class="col-md-4">
              <label>Nombre</label>
              <fg-input class="custom-input"
              type="text"
              v-model="solicitud.NombreF3">
            </fg-input>
            </div>
            <div class="col-md-4">
              <label>Telefono</label>
              <fg-input class="custom-input"
              type="text"
              v-model="solicitud.TelefonoF3">
            </fg-input>
            </div>
            <div class="col-md-4">
              <label>Direccion</label>
              <fg-input class="custom-input"
              v-model="solicitud.DireccionF3">
            </fg-input>
            </div>
          </div>


          <div class="text-center">
            <p-button class="btn-lg" style="margin: 40px;" type="info" round @click.native.prevent="actualizarSolicitudEmpleo">
              Actualizar Solicitud de Empleo
            </p-button>
          </div>
          <div class="clearfix"></div>
        </form>
      </div>
    </card>
  </div>
</template>
<script>
import Multiselect from 'vue-multiselect';
import 'vue-multiselect/dist/vue-multiselect.min.css';
import SolicitudEmpleoService from '@/services/solicitud_empleo_service.js';

export default {
  components: { Multiselect },
  name: 'SolicitudEmpleoForm',
  props: ['id'],

  mounted() {
    this.fetchSolicitudEmpleoDetails();


  },
  data() {
    console.log(this.id);
    return {
      solicitud: {
        Lugar: '',
        Fecha: null,
        NumeroMembresia: '',
        MunicipioId: '',
        DepartamentoId: '',
        Zona: null,
        Nombre: '',
        Direccion: '',
        PuestoSolicitado: '',
        Sueldo: '',
        Identidad: '',
        LugarNacimiento: '',
        FechaNacimiento: null,
        EstadoCivil: null,
        TipoSangre: null,
        TelefonoFijo: '',
        Celular: '',
        CorreoElectronico: null,
        IHSS: '',
        CasaPropia: '',
        ValorCasa: '',
        AlquilerCasa: '',
        CiudadId: '',
        DepartamentId: '',
        CuantosTrabajan: '',
        PoseeVehiculo: '',
        TipoVehiculo: '',
        MarcaVehiculo: '',
        PlacaVehiculo: '',
        AnioVehiculo: '',
        TipoLicencia: '',
        VencimientoLicencia: '',
        OtroVehiculo: '',
        Salud: null,
        EstudiaNoches: null,
        BebidasAlcoholicas: null,
        BebidasFrecuencia: '',
        EstudiaNoches: null,
        NombrePadre: '',
        DireccionPadres: '',
        Conyugue: '',
        Hijos: '',
        CTelefonoFijo: '',
        CCelular: '',
        CCorreoElectronico: null,
        Profesion: '',
        Escuela: '',
        EscuelaGrado: '',
        Instituto: '',
        InstitutoGrado: '',
        TituloSecundaria: '',
        Universidad: '',
        TituloUniversidad: '',
        Cursos: '',
        Equipos: '',
        ServicioMilitar: '',
        UnidadSM: '',
        LugarSM: '',
        PeriodoSM: '',
        ComandanteSM: '',
        RangoSM: '',
        RegistroSM: '',
        NegocioPropio: '',
        NombreE1: null,
        TelefonoE1: '',
        SueldoIE1: '',
        SueldoFE1: '',
        FechaIE1: null,
        PuestoE1: '',
        ObligacionesE1: '',
        NombreE2: '',
        TelefonoE2: '',
        SueldoIE2: '',
        SueldoFE2: '',
        FechaIE2: null,
        PuestoE2: '',
        ObligacionesE2: '',
        NombreE3: '',
        TelefonoE3: '',
        SueldoIE3: '',
        SueldoFE3: '',
        FechaIE3: null,
        PuestoE3: '',
        ObligacionesE3: '',
        Habilidad1: null,
        Habilidad2: '',
        NombreP1: null,
        TelefonoP1: '',
        DireccionP1: '',
        NombreP2: '',
        TelefonoP2: '',
        DireccionP2: '',
        NombreP3: '',
        TelefonoP3: '',
        DireccionP3: '',
        NombreF1: '',
        TelefonoF1: '',
        DireccionF1: '',
        NombreF2: '',
        TelefonoF2: '',
        DireccionF2: '',
        NombreF3: '',
        TelefonoF3: '',
        DireccionF3: '',
      },

      zonaOptions: ['Norte', 'Sur','Este', 'Oeste'],
      estadoCivilOptions: [ 'Casado', 'Soltero', 'Divorciado', 'Viudo', 'Union Libre'],
      tipoSangreOptions: ['A', 'B', 'AB', 'O'],
      casaPropiaOptions: ['Si', 'No'],
      poseeVehiculoOptions: ['Si', 'No'],
      saludOptions: ['No muy buena', 'Buena', 'Excelente'],
      estudiaNochesOptions: ['Si', 'No'],
      servicioMilitarOptions: ['Si', 'No'],
      negocioPropioOptions: ['Si', 'No'],

      //Inicializar variables de opciones
      municipios: [],
      departamentos: [],
      filteredMunicipios: [],
      filteredDepartamentos: [],
      //Manejo de errores
      errores: {},
      };
    },
    methods:{

      fetchCiudades() {
        SolicitudEmpleoService.getCiudades()
        .then(response => {
          this.municipios = response.data.data.map(ciudad => ({
            id: ciudad.id,
            nombre: ciudad.attributes.Nombre
          }));
          this.filteredMunicipios = this.municipios;
        })
        .catch(error => {
          console.error('Error al obtener municipios', error);
        });
    },

    fetchDepartamentos() {
      SolicitudEmpleoService.getDepartamentos()
        .then(response => {
          this.departamentos = response.data.data.map(departamento => ({
            id: departamento.id,
            nombre: departamento.attributes.Nombre
          }));
          this.filteredDepartamentos = this.departamentos;
        })
        .catch(error => {
          console.error('Error al obtener departamentos', error);
        });
    },

    mapCasaPropia(value) {
      return value === 'Si' ? 1 : 0;
    },
    mapPoseeVehiculo(value) {
      return value === 'Si' ? 1 : 0;
    },
    mapEstudiaNoches(value) {
      return value === 'Si' ? 1 : 0;
    },
    mapServicioMilitar(value) {
      return value === 'Si' ? 1 : 0;
    },
    mapNegocioPropio(value) {
      return value === 'Si' ? 1 : 0;
    },

    async actualizarSolicitudEmpleo() {
    const casaPropiaValue = this.mapCasaPropia(this.solicitud.CasaPropia);
    const poseeVehiculoValue = this.mapPoseeVehiculo(this.solicitud.PoseeVehiculo);
    const estudiaNochesValue = this.mapEstudiaNoches(this.solicitud.EstudiaNoches);
    const servicioMilitarValue = this.mapServicioMilitar(this.solicitud.ServicioMilitar);
    const negocioPropioValue = this.mapNegocioPropio(this.solicitud.NegocioPropio);
    this.errores = {}; // Reset errores to ensure it's clean before adding new errors

    try {
      const solicitudId = this.id; // Asumiendo que ya tienes este id en tu componente

      // Enviar solicitud PUT en lugar de POST, incluyendo el ID en la URL
      const response = await SolicitudEmpleoService.putSolicitudEmpleo(solicitudId, {
        DatosGenerales: {
          Lugar: this.solicitud.Lugar,
          Fecha: this.solicitud.Fecha,
          NumeroMembresia: this.solicitud.NumeroMembresia,
          Municipio: this.solicitud.MunicipioId,
          DepartamentoZona: this.solicitud.DepartamentId,
          Zona: this.solicitud.Zona,
          Nombre: this.solicitud.Nombre,
          Direccion: this.solicitud.Direccion,
          PuestoSolicitado: this.solicitud.PuestoSolicitado,
          Sueldo: this.solicitud.Sueldo,
          Identidad: this.solicitud.Identidad,
          LugarNacimiento: this.solicitud.LugarNacimiento,
          FechaNacimiento: this.solicitud.FechaNacimiento,
          EstadoCivil: this.solicitud.EstadoCivil,
          TipoSangre: this.solicitud.TipoSangre,
          TelefonoFijo: this.solicitud.TelefonoFijo,
          Celular: this.solicitud.Celular,
          CorreoElectronico: this.solicitud.CorreoElectronico,
          IHSS: this.solicitud.IHSS,
          CasaPropia: casaPropiaValue,
          ValorCasa: this.solicitud.ValorCasa,
          AlquilerCasa: this.solicitud.AlquilerCasa,
          Ciudad: this.solicitud.CiudadId,
          Departamento: this.solicitud.DepartamentoId,
          CuantosTrabajan: this.solicitud.CuantosTrabajan,
          PoseeVehiculo: poseeVehiculoValue,
          TipoVehiculo: this.solicitud.TipoVehiculo,
          MarcaVehiculo: this.solicitud.MarcaVehiculo,
          PlacaVehiculo: this.solicitud.PlacaVehiculo,
          AnioVehiculo: this.solicitud.AnioVehiculo,
          TipoLicencia: this.solicitud.TipoLicencia,
          VencimientoLicencia: this.solicitud.VencimientoLicencia,
          OtroVehiculo: this.solicitud.OtroVehiculo,
          Salud: this.solicitud.Salud,
          EstudiaNoches: estudiaNochesValue,
          BebidasAlcoholicas: this.solicitud.BebidasAlcoholicas,
          BebidasFrecuencia: this.solicitud.BebidasFrecuencia,
          NombrePadre: this.solicitud.NombrePadre,
          DireccionPadres: this.solicitud.DireccionPadres,
          Conyugue: this.solicitud.Conyugue,
          Hijos: this.solicitud.Hijos,
          CTelefonoFijo: this.solicitud.CTelefonoFijo,
          CCelular: this.solicitud.CCelular,
        },

        NivelAcademico: {
          CCorreoElectronico: this.solicitud.CCorreoElectronico,
          Profesion: this.solicitud.Profesion,
          Escuela: this.solicitud.Escuela,
          EscuelaGrado: this.solicitud.EscuelaGrado,
          Instituto: this.solicitud.Instituto,
          InstitutoGrado: this.solicitud.InstitutoGrado,
          TituloSecundaria: this.solicitud.TituloSecundaria,
          Universidad: this.solicitud.Universidad,
          TituloUniversidad: this.solicitud.TituloUniversidad,
          Cursos: this.solicitud.Cursos,
          Equipos: this.solicitud.Equipos,
          ServicioMilitar: servicioMilitarValue,
          UnidadSM: this.solicitud.UnidadSM,
          LugarSM: this.solicitud.LugarSM,
          PeriodoSM: this.solicitud.PeriodoSM,
          ComandanteSM: this.solicitud.ComandanteSM,
          RangoSM: this.solicitud.RangoSM,
          RegistroSM: this.solicitud.RegistroSM,
        },

        EmpleosAnteriores:{
          NegocioPropio: negocioPropioValue,
          NombreE1: this.solicitud.NombreE1,
          TelefonoE1: this.solicitud.TelefonoE1,
          SueldoIE1: this.solicitud.SueldoIE1,
          SueldoFE1: this.solicitud.SueldoFE1,
          FechaIE1: this.solicitud.FechaIE1,
          PuestoE1: this.solicitud.PuestoE1,
          ObligacionesE1: this.solicitud.ObligacionesE1,
          NombreE2: this.solicitud.NombreE2,
          TelefonoE2: this.solicitud.TelefonoE2,
          SueldoIE2: this.solicitud.SueldoIE2,
          SueldoFE2: this.solicitud.SueldoFE2,
          FechaIE2: this.solicitud.FechaIE2,
          PuestoE2: this.solicitud.PuestoE2,
          ObligacionesE2: this.solicitud.ObligacionesE2,
          NombreE3: this.solicitud.NombreE3,
          TelefonoE3: this.solicitud.TelefonoE3,
          SueldoIE3: this.solicitud.SueldoIE3,
          SueldoFE3: this.solicitud.SueldoFE3,
          FechaIE3: this.solicitud.FechaIE3,
          PuestoE3: this.solicitud.PuestoE3,
          ObligacionesE3: this.solicitud.ObligacionesE3,
        },

        Habilidades:{
          Habilidad1: this.solicitud.Habilidad1,
          Habilidad2: this.solicitud.Habilidad2,
        },

        PersonasConocen:{
          NombreP1: this.solicitud.NombreP1,
          TelefonoP1: this.solicitud.TelefonoP1,
          DireccionP1: this.solicitud.DireccionP1,
          NombreP2: this.solicitud.NombreP2,
          TelefonoP2: this.solicitud.TelefonoP2,
          DireccionP2: this.solicitud.DireccionP2,
          NombreP3: this.solicitud.NombreP3,
          TelefonoP3: this.solicitud.TelefonoP3,
          DireccionP3: this.solicitud.DireccionP3,
        },

        Familiares:{
          NombreF1: this.solicitud.NombreF1,
          TelefonoF1: this.solicitud.TelefonoF1,
          DireccionF1: this.solicitud.DireccionF1,
          NombreF2: this.solicitud.NombreF2,
          TelefonoF2: this.solicitud.TelefonoF2,
          DireccionF2: this.solicitud.DireccionF2,
          NombreF3: this.solicitud.NombreF3,
          TelefonoF3: this.solicitud.TelefonoF3,
          DireccionF3: this.solicitud.DireccionF3,
        }

      });

      this.$toast.success('Solicitud actualizada con éxito', {
        position: "top-right",
        timeout: 5000,
      });
      this.$router.push({ name: 'Miembros con Solicitud de Empleo' });
    } catch (error) {
      console.error('Error al actualizar la solicitud de empleo', error);
      this.$toast.error('Error al actualizar la solicitud de empleo', {
        position: "top-right",
        timeout: 5000,
      });
    }
},

    resetForm() {
      this.solicitud = {
        Lugar: '',
        Fecha: '',
        NumeroMembresia: '',
        MunicipioId: '',
        DepartamentoId: '',
        Zona: '',
        Nombre: '',
        Direccion: '',
        PuestoSolicitado: '',
        Sueldo: '',
        Identidad: '',
        LugarNacimiento: '',
        FechaNacimiento: '',
        EstadoCivil: '',
        TipoSangre: '',
        TelefonoFijo: '',
        Celular: '',
        CorreoElectronico: '',
        IHSS: '',
        CasaPropia: '',
        ValorCasa: '',
        AlquilerCasa: '',
        CiudadId: '',
        DepartamentId: '',
        CuantosTrabajan: '',
        PoseeVehiculo: '',
        TipoVehiculo: '',
        MarcaVehiculo: '',
        PlacaVehiculo: '',
        AnioVehiculo: '',
        TipoLicencia: '',
        VencimientoLicencia: '',
        OtroVehiculo: '',
        Salud: '',
        EstudiaNoches: '',
        BebidasAlcoholicas: '',
        BebidasFrecuencia: '',
        EstudiaNoches: '',
        NombrePadre: '',
        DireccionPadres: '',
        Conyugue: '',
        Hijos: '',
        CTelefonoFijo: '',
        CCelular: '',
        CCorreoElectronico: '',
        Profesion: '',
        Escuela: '',
        EscuelaGrado: '',
        Instituto: '',

      };

    this.errores = {};
    },

    async fetchSolicitudEmpleoDetails() {

      try {
        const solicitudEmpleoId = this.id;

        const solicitudResponse = await SolicitudEmpleoService.fetchSolicitudEmpleo();

        const solicitud = solicitudResponse.data.data.find(item => item.id === solicitudEmpleoId);
        console.log('Solicitud Elegida:', solicitud);

        // Mapear las relaciones
        const departamentoSeleccionado = this.departamentos.find(
          option => option.id === solicitud?.attributes.DatosGenerales.Departamento?.data?.id
        );
        const municipioSeleccionado = this.municipios.find(
          option => option.id === solicitud?.attributes.DatosGenerales.Municipio?.data?.id
        );

        const ciudadSeleccionada = this.municipios.find(
          option => option.id === solicitud?.attributes.DatosGenerales.Ciudad?.data?.id
        );

        const departamentoZonaSeleccionado = this.departamentos.find(
          option => option.id === solicitud?.attributes.DatosGenerales.DepartamentoZona?.data?.id
        );

        // if(solicitud?.attributes.DatosGenerales.BebidasAlcoholicas == true){
        //   solicitud.attributes.DatosGenerales.BebidasAlcoholicas = 'Si';
        // }else{
        //   solicitud.attributes.DatosGenerales.BebidasAlcoholicas = 'No';
        // }

        if(solicitud?.attributes.DatosGenerales.EstudiaNoches == true){
          solicitud.attributes.DatosGenerales.EstudiaNoches = 'Si';
        }else{
          solicitud.attributes.DatosGenerales.EstudiaNoches = 'No';
        }
        if(solicitud?.attributes.DatosGenerales.CasaPropia == true){
          solicitud.attributes.DatosGenerales.CasaPropia = 'Si';
        }else{
          solicitud.attributes.DatosGenerales.CasaPropia = 'No';
        }
        if(solicitud?.attributes.DatosGenerales.PoseeVehiculo == true){
          solicitud.attributes.DatosGenerales.PoseeVehiculo = 'Si';
        }else{
          solicitud.attributes.DatosGenerales.PoseeVehiculo = 'No';
        }
        if(solicitud?.attributes.NivelAcademico.ServicioMilitar == true){
          solicitud.attributes.NivelAcademico.ServicioMilitar = 'Si';
        }else{
          solicitud.attributes.DatosGenerales.ServicioMilitar = 'No';
        }
        if(solicitud?.attributes.EmpleosAnteriores.NegocioPropio == true){
          solicitud.attributes.EmpleosAnteriores.NegocioPropio = 'Si';
        }else{
          solicitud.attributes.EmpleosAnteriores.NegocioPropio = 'No';
        }

        this.solicitud = {
        Lugar: solicitud?.attributes.DatosGenerales.Lugar || "",
        Fecha: solicitud?.attributes.DatosGenerales.Fecha || null,
        NumeroMembresia: solicitud?.attributes.DatosGenerales.NumeroMembresia || "",
        Municipio: solicitud?.attributes.DatosGenerales.Municipio || null,
        Departamento: solicitud?.attributes.DatosGenerales.Departamento || "",
        Zona: solicitud?.attributes.DatosGenerales.Zona || null,
        Nombre: solicitud?.attributes.DatosGenerales.Nombre || "",
        Direccion: solicitud?.attributes.DatosGenerales.Direccion || "",
        PuestoSolicita: solicitud?.attributes.DatosGenerales.PuestoSolicita || "",
        SueldoMensual: solicitud?.attributes.DatosGenerales.Sueldo || "",
        Identidad: solicitud?.attributes.DatosGenerales.Identidad || "",
        LugarNacimiento: solicitud?.attributes.DatosGenerales.LugarNacimiento || "",
        FechaNacimiento: solicitud?.attributes.DatosGenerales.FechaNacimiento || null,
        EstadoCivil: solicitud?.attributes.DatosGenerales.EstadoCivil || null,
        TipoSangre: solicitud?.attributes.DatosGenerales.TipoSangre || null,
        TelefonoFijo: solicitud?.attributes.DatosGenerales.TelefonoFijo || "",
        Celular: solicitud?.attributes.DatosGenerales.Celular || "",
        CorreoElectronico: solicitud?.attributes.DatosGenerales.CorreoElectronico || null,
        IHSS: solicitud?.attributes.DatosGenerales.IHSS || "",
        CasaPropia: solicitud?.attributes.DatosGenerales.CasaPropia || null,
        ValorCasa: solicitud?.attributes.DatosGenerales.ValorCasa || "",
        AlquilerCasa: solicitud?.attributes.DatosGenerales.AlquilerCasa || "",
        Ciudad: solicitud?.attributes.DatosGenerales.Ciudad || null,
        DepartamentoZona: solicitud?.attributes.DatosGenerales.DepartamentoZona || null,
        CuantosTrabajan: solicitud?.attributes.DatosGenerales.CuantosTrabajan || "",
        PoseeVehiculo: solicitud?.attributes.DatosGenerales.PoseeVehiculo || null,
        TipoVehiculo: solicitud?.attributes.DatosGenerales.TipoVehiculo || "",
        MarcaVehiculo: solicitud?.attributes.DatosGenerales.MarcaVehiculo || "",
        PlacaVehiculo: solicitud?.attributes.DatosGenerales.PlacaVehiculo || "",
        AnioVehiculo: solicitud?.attributes.DatosGenerales.AnioVehiculo || "",
        TipoLicencia: solicitud?.attributes.DatosGenerales.TipoLicencia || "",
        VencimientoLicencia: solicitud?.attributes.DatosGenerales.VencimientoLicencia || "",
        OtroVehiculo: solicitud?.attributes.DatosGenerales.OtroVehiculo || "",
        Salud: solicitud?.attributes.DatosGenerales.Salud || null,
        EstudiaNoches: solicitud?.attributes.DatosGenerales.EstudiaNoches || null,
        BebidasAlcoholicas: solicitud?.attributes.DatosGenerales.BebidasAlcoholicas || null,
        BebidasFrecuencia: solicitud?.attributes.DatosGenerales.BebidasFrecuencia || "",
        EstudiaNoches: solicitud?.attributes.DatosGenerales.EstudiaNoches || null,
        NombrePadre: solicitud?.attributes.DatosGenerales.NombrePadre || "",
        DireccionPadres: solicitud?.attributes.DatosGenerales.DireccionPadres || "",
        Conyugue: solicitud?.attributes.DatosGenerales.Conyugue || "",
        Hijos: solicitud?.attributes.DatosGenerales.Hijos || "",
        CTelefonoFijo: solicitud?.attributes.DatosGenerales.CTelefonoFijo || "",
        CCelular: solicitud?.attributes.DatosGenerales.CCelular || "",
        CCorreoElectronico: solicitud?.attributes.DatosGenerales.CCorreoElectronico || null,
        Profesion: solicitud?.attributes.NivelAcademico.Profesion || "",
        Escuela: solicitud?.attributes.NivelAcademico.Escuela || "",
        EscuelaGrado: solicitud?.attributes.NivelAcademico.EscuelaGrado || "",
        Instituto: solicitud?.attributes.NivelAcademico.Instituto || "",
        InstitutoGrado: solicitud?.attributes.NivelAcademico.InstitutoGrado || "",
        TituloSecundaria: solicitud?.attributes.NivelAcademico.TituloSecundaria || "",
        Universidad: solicitud?.attributes.NivelAcademico.Universidad || "",
        TituloUniversidad: solicitud?.attributes.NivelAcademico.TituloUniversidad || "",
        Cursos: solicitud?.attributes.NivelAcademico.Cursos || "",
        Equipos: solicitud?.attributes.NivelAcademico.Equipos || "",
        ServicioMilitar: solicitud?.attributes.NivelAcademico.ServicioMilitar || null,
        UnidadSM: solicitud?.attributes.NivelAcademico.UnidadSM || "",
        LugarSM: solicitud?.attributes.NivelAcademico.LugarSM || "",
        PeriodoSM: solicitud?.attributes.NivelAcademico.PeriodoSM || "",
        ComandanteSM: solicitud?.attributes.NivelAcademico.ComandanteSM || "",
        RangoSM: solicitud?.attributes.NivelAcademico.RangoSM || "",
        RegistroSM: solicitud?.attributes.NivelAcademico.RegistroSM || "",
        NegocioPropio: solicitud?.attributes.EmpleosAnteriores.NegocioPropio || null,
        NombreE1: solicitud?.attributes.EmpleosAnteriores.NombreE1 || "",
        TelefonoE1: solicitud?.attributes.EmpleosAnteriores.TelefonoE1 || "",
        SueldoIE1: solicitud?.attributes.EmpleosAnteriores.SueldoIE1 || "",
        SueldoFE1: solicitud?.attributes.EmpleosAnteriores.SueldoFE1 || "",
        FechaIE1: solicitud?.attributes.EmpleosAnteriores.FechaIE1 || null,
        PuestoE1: solicitud?.attributes.EmpleosAnteriores.PuestoE1 || "",
        ObligacionesE1: solicitud?.attributes.EmpleosAnteriores.ObligacionesE1 || "",
        NombreE2: solicitud?.attributes.EmpleosAnteriores.NombreE2 || "",
        TelefonoE2: solicitud?.attributes.EmpleosAnteriores.TelefonoE2 || "",
        SueldoIE2: solicitud?.attributes.EmpleosAnteriores.SueldoIE2 || "",
        SueldoFE2: solicitud?.attributes.EmpleosAnteriores.SueldoFE2 || "",
        FechaIE2: solicitud?.attributes.EmpleosAnteriores.FechaIE2 || null,
        PuestoE2: solicitud?.attributes.EmpleosAnteriores.PuestoE2 || "",
        ObligacionesE2: solicitud?.attributes.EmpleosAnteriores.ObligacionesE2 || "",
        NombreE3: solicitud?.attributes.EmpleosAnteriores.NombreE3 || "",
        TelefonoE3: solicitud?.attributes.EmpleosAnteriores.TelefonoE3 || "",
        SueldoIE3: solicitud?.attributes.EmpleosAnteriores.SueldoIE3 || "",
        SueldoFE3: solicitud?.attributes.EmpleosAnteriores.SueldoFE3 || "",
        FechaIE3: solicitud?.attributes.EmpleosAnteriores.FechaIE3 || null,
        PuestoE3: solicitud?.attributes.EmpleosAnteriores.PuestoE3 || "",
        ObligacionesE3: solicitud?.attributes.EmpleosAnteriores.ObligacionesE3 || "",
        Habilidad1: solicitud?.attributes.Habilidades.Habilidad1 || null,
        Habilidad2: solicitud?.attributes.Habilidades.Habilidad2 || "",
        NombreP1: solicitud?.attributes.PersonasConocen.NombreP1 || null,
        TelefonoP1: solicitud?.attributes.PersonasConocen.TelefonoP1 || "",
        DireccionP1: solicitud?.attributes.PersonasConocen.DireccionP1 || "",
        NombreP2: solicitud?.attributes.PersonasConocen.NombreP2 || "",
        TelefonoP2: solicitud?.attributes.PersonasConocen.TelefonoP2 || "",
        DireccionP2: solicitud?.attributes.PersonasConocen.DireccionP2 || "",
        NombreP3: solicitud?.attributes.PersonasConocen.NombreP3 || "",
        TelefonoP3: solicitud?.attributes.PersonasConocen.TelefonoP3 || "",
        DireccionP3: solicitud?.attributes.PersonasConocen.DireccionP3 || "",
        NombreF1: solicitud?.attributes.Familiares.NombreF1 || "",
        TelefonoF1: solicitud?.attributes.Familiares.TelefonoF1 || "",
        DireccionF1: solicitud?.attributes.Familiares.DireccionF1 || "",
        NombreF2: solicitud?.attributes.Familiares.NombreF2 || "",
        TelefonoF2: solicitud?.attributes.Familiares.TelefonoF2 || "",
        DireccionF2: solicitud?.attributes.Familiares.DireccionF2 || "",
        NombreF3: solicitud?.attributes.Familiares.NombreF3 || "",
        TelefonoF3: solicitud?.attributes.Familiares.TelefonoF3 || "",
        DireccionF3: solicitud?.attributes.Familiares.DireccionF3 || "",


        };


        this.$set(this.solicitud, 'DepartamentoId', departamentoSeleccionado || null);
        this.$set(this.solicitud, 'MunicipioId', municipioSeleccionado || null);
        this.$set(this.solicitud, 'CiudadId', ciudadSeleccionada || null);
        this.$set(this.solicitud, 'DepartamentId', departamentoZonaSeleccionado || null);

        // this.$set(this.matrimonio, 'DepartamentoId', departamentoSeleccionado || null);
        // this.$set(this.matrimonio, 'MunicipioId', municipioSeleccionado || null);
        // console.log('Matrimonio:', this.matrimonio);

        console.log('Data que viene:', this.solicitud);

      } catch (error) {
        console.error('Error fetching Solicitud de Empleo details:', error);
      }
    },//Termina fetchSolicitudEmpleoDetails

    },
    created() {
      this.fetchCiudades();
      this.fetchDepartamentos();

    },


};
</script>

<style scoped>
.custom-input {
  border: solid 1px #e4e7ea;
  margin-bottom: 25px;
}
.custom-multiselect {
  font-size: 14px;
  color: #66615b;
  margin-bottom: 25px;
}
::v-deep label {
  color: #252422 !important;
}

.row h4 {
  font-size: 18px;
  font-weight: 600;
  color: #555; /* Color de texto gris */
  background-color: #f1f1f1; /* Fondo ligeramente gris */
  padding: 8px 16px; /* Espaciado interno */
  border-radius: 4px; /* Bordes ligeramente redondeados */
  margin-bottom: 15px; /* Espacio debajo del título */
  text-align: left; /* Alinear el texto a la izquierda */
}

.required-asterisk {
  color: red;
  margin-left: 4px;
}

</style>

