import apiClient from './api';

// sessionStorage.setItem('token', process.env.VUE_APP_API_BEARER_TOKEN);
export default {
  // Metodos GET para obtener los datos en Membresia
  fetchMembresias() {
    return apiClient.get('/membresias?populate[0]=Ubicacion&populate[1]=Ubicacion.Departamento&populate[2]=Ubicacion.Municipio&populate[3]=Ubicacion.Localidad&populate[4]=Ubicacion.ZonaMic&populate[5]=Ubicacion.Zona&populate[6]=DatosGenerales&populate[7]=DatosGenerales.Departamento&populate[8]=DatosGenerales.Ciudad&populate[9]=DatosProfesionales&populate[10]=DatosEmpresariales&populate[11]=DatosEspirituales&populate[12]=DatosEspirituales.Don&populate[13]=DatosEspirituales.Test');
  },

  // Metodos GET para cargar los datos en los combobox en Membresia
  getCiudades() {
    return apiClient.get('/municipios');
  },
  getDepartamentos() {
    return apiClient.get('/departamentos');
  },
  getLocalidades() {
    return apiClient.get('/localidades');
  },

  // Metodos POST para guardar los datos de Membresia
  postMembresias(data) {
    return apiClient.post('/membresias', { data }).then(response => response.data);;
  },

  putMembresias(id, data) {
    return apiClient.put(`/membresias/${id}`, { data });
  },


};



