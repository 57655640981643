import axios from 'axios';

// Función para obtener el token
function getToken() {
  return sessionStorage.getItem('jwt');

}

const apiClient = axios.create({
  baseURL: process.env.VUE_APP_API_URL,
  headers: {
    'Content-Type': 'application/json',
  },
});

// Interceptor para agregar el token a cada petición
apiClient.interceptors.request.use(
  (config) => {
    const token = getToken();
    // if (token) {
    //   const decodedToken = JSON.parse(atob(token.split('.')[1])); // Decodificar el payload del JWT

    //   if (decodedToken.role === 'admin') {
    //     // Permitir acceso a rutas de administrador
    //   } else {
    //     // Redirigir a una página de acceso denegado o similar
    //     window.location.href = '/login';
    //   }
    // }
    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default apiClient;


