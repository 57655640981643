<template>
  <div class="membresia-form-page">
    <MembresiaForm />
  </div>
</template>

<script>
import MembresiaForm from '@/pages/Formularios/MembresiaForm.vue';

export default {
  name: "MembresiaFormPage",
  components: {
    MembresiaForm,
  },
};
</script>

<style scoped>
.membresia-form-page {
  padding: 20px;
}
</style>
