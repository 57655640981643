<template>
  <div class="modal" :class="{ show: showModal }" tabindex="-1" role="dialog" @click.self="close">
    <div class="modal-dialog modal-lg" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Detalles del Registro</h5>
          <button type="button" class="close" aria-label="Close" @click="close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <ul>
            <li v-for="(value, key) in filteredDetails" :key="key">
              <strong>{{ key }}:</strong>
              <template v-if="typeof value === 'object' && value !== null">
                <ul>
                  <li v-for="(subValue, subKey) in value" :key="subKey">
                    <strong>{{ subKey }}:</strong> {{ subValue }}
                  </li>
                </ul>
              </template>
              <template v-else>
                {{ value }}
              </template>
            </li>
          </ul>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" @click="close">Cerrar</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    showModal: {
      type: Boolean,
      required: true
    },
    details: {
      type: Object,
      required: true
    }
  },
  computed: {
    filteredDetails() {
      if (!this.details) {
        return {};
      }
      //NOTA PRIOPIA> AQUI SE CAMBIAN LOS CAMPOS DEL MODAL
      const keysToShow = [

      "Lugar", "Fecha" , "NumeroMembresia", "Municipio", "Departamento", "Zona", "Nombre","Direccion", "PuestoSolicita","Sueldo", "Identidad", "LugarNacimiento","FechaNacimiento", "EstadoCivil",
      "TelefonoFijo", "CorreoElectronico","Celular", "IHSS", "TipoSangre", "CasaPropia", "ValorCasa", "AlquilerCasa", "Ciudad", "DepartamentoZona", "CuantosTrabajan",
      "PoseeVehiculo", "TipoVehiculo", "MarcaVehiculo", "PlacaVehiculo", "AnioVehiculo", "TipoLicencia", "VencimientoLicencia", "OtroVehiculo", "Salud", "BebidasAlcoholicas",
      "BebidasFrecuencia", "EstudiaNoches", "NombrePadre", "DireccionPadres", "Conyugue", "Hijos", "CCorreoElectronico", "CTelefonoFijo", "CCelular",
      "Profesion", "Escuela", "EscuelaGrado", "Insttituto", "InstritutoGrado", "TituloSecundaria", "Universidad", "TituloUniversidad", "Cursos", "Equipos",
      "ServicioMilitar", "UnidadSM", "LugarSM", "PeriodoSM", "ComandanteSM", "RangoSM", "RegistroSM",
      "NegocioPropio", "NombreE1", "TelefonoE1", "SueldoIE1", "SueldoFE1", "FechaIE1", "PuestoE1", "ObligacionesE1", "NombreE2", "TelefonoE2", "SueldoIE2", "SueldoFE2", "FechaIE2", "PuestoE2", "ObligacionesE2",
      "NombreE3", "TelefonoE3", "SueldoIE3", "SueldoFE3", "FechaIE3", "PuestoE3", "ObligacionesE3",
      "Habilidad1", "Habilidad2", "NombreP1", "TelefonoP1", "DireccionP1", "NombreP2", "TelefonoP2", "DireccionP2", "NombreP3", "TelefonoP3", "DireccionP3",
      "NombreF1", "TelefonoF1", "DireccionF1", "NombreF2", "TelefonoF2", "DireccionF2", "NombreF3", "TelefonoF3", "DireccionF3",

      ];
      return Object.keys(this.details)
        .filter(key => keysToShow.includes(key) && this.details[key] !== undefined)
        .reduce((obj, key) => {
          obj[key] = this.details[key];
          return obj;
        }, {});
    }
  },
  methods: {
    close() {
      this.$emit('close');
    },
  }
};
</script>

<style scoped>
.modal {
  display: none;
}
.modal.show {
  display: block;
  background-color: rgba(0, 0, 0, 0.5);
}
.modal-body {
  max-height: 400px; /* Adjust the height as needed */
  overflow-y: auto; /* Enables vertical scrolling if the content exceeds the max-height */
}
</style>
