<template>
  <div>
    <!-- Stats cards-->
    <div class="row">
      <div
        class="col-md-6 col-xl-4"
        v-for="stats in statsCards"
        :key="stats.title"
      >
        <stats-card>
          <div
            class="icon-big text-center"
            :class="`icon-${stats.type}`"
            slot="header"
          >
            <i :class="stats.icon "></i>
          </div>
          <div class="numbers" slot="content">
            <p>{{ stats.title }}</p>
            {{ stats.value }}
          </div>
          <div class="stats" slot="footer">
            <a :href="stats.link">
              <i :class="stats.footerIcon"></i> {{ stats.footerText }}
            </a>
          </div>
        </stats-card>
      </div>
    </div>

    <!-- Charts -->
    <!-- <div class="row">
      <div class="col-12">
        <chart-card
          title="Users behavior"
          sub-title="24 Hours performance"
          :chart-data="usersChart.data"
          :chart-options="usersChart.options"
        >
          <span slot="footer">
            <i class="ti-reload"></i> Updated 3 minutes ago
          </span>
          <div slot="legend">
            <i class="fa fa-circle text-info"></i> Open
            <i class="fa fa-circle text-danger"></i> Click
            <i class="fa fa-circle text-warning"></i> Click Second Time
          </div>
        </chart-card>
      </div>

      <div class="col-md-6 col-12">
        <chart-card
          title="Email Statistics"
          sub-title="Last campaign performance"
          :chart-data="preferencesChart.data"
          chart-type="Pie"
        >
          <span slot="footer">
            <i class="ti-timer"></i> Campaign set 2 days ago</span
          >
          <div slot="legend">
            <i class="fa fa-circle text-info"></i> Open
            <i class="fa fa-circle text-danger"></i> Bounce
            <i class="fa fa-circle text-warning"></i> Unsubscribe
          </div>
        </chart-card>
      </div>

      <div class="col-md-6 col-12">
        <chart-card
          title="2015 Sales"
          sub-title="All products including Taxes"
          :chart-data="activityChart.data"
          :chart-options="activityChart.options"
        >
          <span slot="footer">
            <i class="ti-check"></i> Data information certified
          </span>
          <div slot="legend">
            <i class="fa fa-circle text-info"></i> Tesla Model S
            <i class="fa fa-circle text-warning"></i> BMW 5 Series
          </div>
        </chart-card>
      </div>
    </div> -->
  </div>
</template>
<script>
import { StatsCard, ChartCard } from "@/components/index";
import membresiasService from '@/services/membresias_service';
import matrimoniosService from '@/services/matrimonios_service';
import solicitudesService from '@/services/solicitud_empleo_service.js'
import Chartist from "chartist";

export default {
  components: {
    StatsCard,
    ChartCard,
  },

  data() {
    return {
      totalRegistrants: 0,
      totalMatrimonios: 0,
      totalSolicitudes:0,
      statsCards: [
        {
          type: "warning",
          icon: "ti-user",
          title: "Membresias MIC Los Granados",
          value: 0, // Inicializar en 0, se actualizará después
          footerText: "Ingresar Membresia",
          footerIcon: "ti-arrow-right",
          link: '/inscripcion-membresia',
        },

        {
          type: "danger",
          icon: "ti-heart",
          title: "Matrimonios MIC Los Granados",
          value: 0, // Inicializar en 0, se actualizará después
          footerText: "Ingresar Matrimonio",
          footerIcon: "ti-arrow-right",
          link: '/inscripcion-matrimonio',
        },
        {
          type: "success",
          icon: "ti-briefcase",
          title: "Solicitudes de Empleo Higuertropic",
          value: 0, // Inicializar en 0, se actualizará después
          footerText: "Ingresar Solicitud",
          footerIcon: "ti-arrow-right",
          link: '/inscripcion-solicitud-empleo',
        }
      ],
    };
  },

  mounted() {
    // Para total de membresias
this.fetchAndUpdateCard(membresiasService.fetchDatosGenerales, 0);

// Para total de matrimonios
this.fetchAndUpdateCard(matrimoniosService.fetchMatrimonios, 1);

// Para total de solicitudes
this.fetchAndUpdateCard(solicitudesService.fetchSolicitudEmpleo, 2);

  },

  methods: {
        async fetchAndUpdateCard(serviceMethod, cardIndex) {
      try {
        const response = await serviceMethod();
        const totalItems = response.data.data.length;
        this.animateCounter(cardIndex, totalItems); // Iniciar la animación del contador
      } catch (error) {
        console.error(`Error fetching data for card ${cardIndex}:`, error);
        this.updateStatsCard(cardIndex, 0); // Mostrar 0 en caso de error
      }
    },

    updateStatsCard(cardIndex, value) {
      // Usar Vue.set para garantizar reactividad
      this.$set(this.statsCards[cardIndex], 'value', value);
    },
    animateCounter(cardIndex, finalValue) {
      let currentValue = 0;
      const increment = Math.ceil(finalValue / 100); // Ajustar incremento si es necesario
      const interval = setInterval(() => {
        if (currentValue < finalValue) {
          currentValue += increment;
          if (currentValue > finalValue) currentValue = finalValue; // Evitar que exceda el valor final
          this.updateStatsCard(cardIndex, currentValue);
        } else {
          clearInterval(interval); // Detener el intervalo cuando se alcance el valor final
        }
      }, 50); // Ajustar velocidad según sea necesario
    }


  }
};
</script>
<style></style>
