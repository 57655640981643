<template>
  <div class="membresia-form-page">
    <EditarMatrimonioForm :id="id" />
  </div>
</template>

<script>
import EditarMatrimonioForm from '@/pages/Formularios/EditarMatrimonioForm.vue';


export default {
  name: "MembresiaFormPage",
  components: {
    EditarMatrimonioForm,
  },
  data() {
    return {
      id: this.$route.params.id, // Asegúrate de que el id se obtenga correctamente de las rutas
    };
  },

};
</script>

<style scoped>
.membresia-form-page {
  padding: 20px;
}
</style>
