<template>
  <nav class="navbar navbar-expand-lg navbar-light">
    <div class="container-fluid">
      <a class="navbar-brand" href="#">{{ routeName }}</a>
      <button
        class="navbar-toggler navbar-burger"
        type="button"
        @click="toggleSidebar"
        :aria-expanded="$sidebar.showSidebar"
        aria-label="Toggle navigation"
      >
        <span class="navbar-toggler-bar"></span>
        <span class="navbar-toggler-bar"></span>
        <span class="navbar-toggler-bar"></span>
      </button>
      <div class="collapse navbar-collapse">
        <ul class="navbar-nav ml-auto">
          <!-- <li class="nav-item">
            <a href="#" class="nav-link">
              <i class="ti-stats-up"></i>
              <p>Estadisticas</p>
            </a>
          </li> -->
          <!-- <drop-down
            class="nav-item"
            title="Notificaciones"
            title-classes="nav-link"
            icon="ti-bell"
          >
            <a class="dropdown-item" href="#"><i class="ti-check" style="color: #229863;"></i>Membresía 0123 registrada</a>
            <a class="dropdown-item" href="#"><i class="ti-close" style="color: #EB5E28;"></i>Membresía 4598 deshabilitada</a>
            <a class="dropdown-item" href="#"><i class="ti-check" style="color: #229863;"></i>Solicitud de Empleo 4567 registrada</a>
            <a class="dropdown-item" href="#"><i class="ti-info-alt" style="color:#68B3C8;"></i>Membresía 0123 falló actualización</a>
            <a class="dropdown-item" href="#"><i class="ti-check" style="color: #229863;"></i>Matrimonio 8910 registrado</a>
          </drop-down> -->
          <li class="nav-item">
            <a href="#" class="nav-link" @click="logout">
              <i class="ti-shift-right"></i>
              <p>Cerrar Sesion</p>
            </a>
          </li>
        </ul>
      </div>
    </div>
  </nav>
</template>
<script>
export default {
  computed: {
    routeName() {
      const { name } = this.$route;
      return this.capitalizeFirstLetter(name);
    },
  },
  data() {
    return {
      activeNotifications: false,
    };
  },
  methods: {

    logout() {
      // Ask the user for confirmation
      if (confirm('¿Desea abandonar esta sesión?')) {
        // Remove the token
        sessionStorage.removeItem('token');

        // Redirect to the login page
        this.$router.push({ name: 'login' });
      }
    },
    capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },
    toggleNotificationDropDown() {
      this.activeNotifications = !this.activeNotifications;
    },
    closeDropDown() {
      this.activeNotifications = false;
    },
    toggleSidebar() {
      this.$sidebar.displaySidebar(!this.$sidebar.showSidebar);
    },
    hideSidebar() {
      this.$sidebar.displaySidebar(false);
    },
  },
};
</script>
<style></style>
