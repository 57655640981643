<template>
  <div class="card">
    <div class="card-image" v-if="$slots.image">
      <slot name="image"></slot>
    </div>
    <div class="card-header row" v-if="$slots.header || title">
      <!-- Title and Subtitle -->
      <div class="col-12 col-md-4">
        <slot name="header">
          <h4 class="card-title">{{ title }}</h4>
          <p class="card-category" v-if="subTitle">{{ subTitle }}</p>
        </slot>
      </div>
      <!-- Button -->
      <div class="col-12 col-md-4 my-2 my-md-0">
        <button class="btn btn-default w-100" @click="navigateToInscripcion">
          <i class="ti-briefcase"></i> Ingresar Solicitud de Empleo
        </button>
      </div>
      <!-- Search Bar -->
      <div class="col-12 col-md-4">
        <input id="barraBusqueda" type="text" class="form-control w-100" placeholder="Buscar registros...">
      </div>
    </div>
    <div class="card-body" v-if="$slots.default">
      <slot></slot>
    </div>
    <slot name="raw-content"></slot>
    <div class="card-footer" v-if="$slots.footer">
      <hr />
      <slot name="footer"></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: "card",
  props: {
    title: String,
    subTitle: String,
  },
  methods: {
    navigateToInscripcion() {
      this.$router.push({ name: 'Inscripcion Solicitud de Empleo'});
    },
  },
};
</script>

<style scoped>
.card-header .col-12, .card-header .col-md-4 {
  padding: 5px;
}
#btnFormularioInscripcion {
  width: 100vh;
}
#barraBusqueda {
  background-color: #f0f0f0; /* Cambia el color de fondo */
  color: #495057; /* Cambia el color del texto */
  border-color: #ced4da; /* Cambia el color del borde si es necesario */
  border-radius: 0.25rem; /* Ajusta el redondeo del borde si es necesario */
  margin-bottom: 20px;
}
#barraBusqueda:focus {
  background-color: #fff; /* Cambia el color de fondo */
  color: #495057; /* Cambia el color del texto */
  border-color: #80bdff; /* Cambia el color del borde si es necesario */
  outline: 0; /* Quita el resplandor azul */
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25); /* Agrega un resplandor azul si es necesario */
  margin-bottom: 20px;
}
input::placeholder {
  color: #495057; /* Cambia el color del texto del marcador de posición */
  opacity: 2; /* Agrega opacidad al marcador de posición */
}
</style>
