<template>
  <div class="row">
    <div class="col-xl-12 col-lg-7 col-md-6">
      <MatrimoniosTable />
    </div>
  </div>
</template>
<script>

import MatrimoniosTable from "@/tables/MatrimoniosTable.vue";


export default {
  components: {
    MatrimoniosTable,
  },
};
</script>
<style></style>


