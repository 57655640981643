<template>
  <div>
    <card class="card" title="Formato Inscripción Matrimonios" subTitle="Solicito muy repestuosamente a la SECRETARÍA NACIONAL DE MEMBRESIA de MIC LOS GRANADOS mi inscripción como matrimonio de esta institución para lo cual proporciono los siguientes datos">
      <div v-if="errores && Object.keys(errores).length" class="alert alert-warning" ref="erroresContainer">
        <p v-for="(mensaje, campo) in errores" :key="campo">{{ campo }} inválido: {{ mensaje }}</p>
      </div>
      <!-- Tabla Datos Generales -->
      <h5 style="background-color: #f1f1f1; padding: 10px; text-align: center; color: #252422;">
            <i class="ti-id-badge"></i> Datos Generales
          </h5>
      <div>
        <form @submit.prevent="crearMatrimonio">

          <div class="row">
            <div class="col-md-6">
              <label>Tipo de Matrimonio</label>
              <multiselect
                class="custom-multiselect"
                v-model="matrimonio.TipoMatrimonio"
                :options="matrimonioOptions"
                :searchable="false"
                :close-on-select="true"
                :clear-on-select="false"
                :allow-empty="false"
                placeholder="Seleccione una opción"
                :show-labels="false"
              ></multiselect>
            </div>
          </div>

          <!--Datos Esposo-->
          <div class="row">
            <div class="col-md-6">
              <label>Nombre del Esposo</label>
              <fg-input class="custom-input"
              type="text"
              v-model="matrimonio.NombreEsposo">
            </fg-input>
            </div>
          </div>

          <div class="row">
            <div class="col-md-6">
              <label>Profesion</label>
              <fg-input class="custom-input"
              type="text"
              v-model="matrimonio.ProfesionEsposo">
            </fg-input>
            </div>
            <div class="col-md-6">
              <label>Numero de Identidad</label>
              <fg-input class="custom-input"
              type="text"
              v-model="matrimonio.IdentidadEsposo">
            </fg-input>
            </div>
          </div>

          <div class="row">
            <div class="col-md-6">
              <label>Celular</label>
              <fg-input class="custom-input"
              type="text"
              v-model="matrimonio.CelularEsposo">
            </fg-input>
            </div>
            <div class="col-md-6">
              <label>Correo Electrónico</label>
              <fg-input class="custom-input"
              type="text"
              v-model="matrimonio.CorreoEsposo">
            </fg-input>
            </div>
          </div>

           <div class="row">
            <div class="col-md-6">
              <label>Numero Membresia MIC</label>
              <fg-input class="custom-input"
              type="text"
              v-model="matrimonio.MembresiaEsposo">
            </fg-input>
            </div>
            <div class="col-md-6">
              <label>No. Empleado Higuertropic</label>
              <fg-input class="custom-input"
              type="text"
              v-model="matrimonio.EmpleadoHigertropicEsposo">
            </fg-input>
            </div>
          </div>

           <!--Datos Esposa-->
           <div class="row">
            <div class="col-md-6">
              <label>Nombre de la Esposa</label>
              <fg-input class="custom-input"
              type="text"
              v-model="matrimonio.NombreEsposa">
            </fg-input>
            </div>
          </div>

          <div class="row">
            <div class="col-md-6">
              <label>Profesion</label>
              <fg-input class="custom-input"
              type="text"
              v-model="matrimonio.ProfesionEsposa">
            </fg-input>
            </div>
            <div class="col-md-6">
              <label>Numero de Identidad</label>
              <fg-input class="custom-input"
              type="text"
              v-model="matrimonio.IdentidadEsposa">
            </fg-input>
            </div>
          </div>

           <div class="row">
            <div class="col-md-6">
              <label>Celular</label>
              <fg-input class="custom-input"
              type="text"
              v-model="matrimonio.CelularEsposa">
            </fg-input>
            </div>
            <div class="col-md-6">
              <label>Correo Electrónico</label>
              <fg-input class="custom-input"
              type="text"
              v-model="matrimonio.CorreoEsposa">
            </fg-input>
            </div>
          </div>

          <div class="row">
            <div class="col-md-6">
              <label>Numero Membresia MIC</label>
              <fg-input class="custom-input"
              type="text"
              v-model="matrimonio.MembresiaEsposa">
            </fg-input>
            </div>
            <div class="col-md-6">
              <label>No. Empleado Higuertropic</label>
              <fg-input class="custom-input"
              type="text"
              v-model="matrimonio.EmpleadoHigertropicEsposa">
            </fg-input>
            </div>
          </div>

          <!-- Ubicacion -->
          <div class="row">
            <div id="municipios" class="col-md-6">
              <label>Municipio</label>
              <multiselect
                class="custom-multiselect"
                v-model="matrimonio.MunicipioId"
                :options="filteredMunicipios"
                :searchable="true"
                :close-on-select="true"
                :clear-on-select="true"
                :allow-empty="false"
                placeholder="Seleccione una opción"
                :show-labels="false"
                label="nombre"
                track-by="id"
                selectLabel=""
                @input="filterCiudades"
              ></multiselect>
            </div>
            <div id="departamentos" class="col-md-6">
              <label>Departamento</label>
              <multiselect
                class="custom-multiselect"
                v-model="matrimonio.DepartamentoId"
                :options="filteredDepartamentos"
                :searchable="true"
                :close-on-select="true"
                :clear-on-select="true"
                :allow-empty="false"
                placeholder="Seleccione una opción"
                :show-labels="false"
                label="nombre"
                track-by="id"
                selectLabel=""
                @input="filterDepartamentos"
              ></multiselect>
            </div>
          </div>

          <div class="row">
            <div class="col-md-12">
              <label>Direccion</label>
              <fg-input
                class="custom-input"
                type="text"
                v-model="matrimonio.Direccion"
              ></fg-input>
            </div>
          </div>

          <!-- Hijos -->
          <div class="row">
            <div class="col-md-4">
              <label>Numero de Hijos</label>
              <multiselect
                class="custom-multiselect"
                v-model="matrimonio.NumeroHijos"
                :options="hijosOptions"
                :searchable="false"
                :close-on-select="true"
                :clear-on-select="false"
                :allow-empty="false"
                placeholder="Seleccione una opción"
                :show-labels="false"
              ></multiselect>
            </div>
          </div>

          <div class="row">
            <div class="col-md-6">
              <label>Nombre</label>
              <fg-input
                class="custom-input"
                type="text"
                v-model="matrimonio.NombreH1"
              ></fg-input>
            </div>
            <div class="col-md-4">
              <label>Edad</label>
              <fg-input
                class="custom-input"
                type="text"
                v-model="matrimonio.EdadH1"
              ></fg-input>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6">
              <label>Nombre</label>
              <fg-input
                class="custom-input"
                type="text"
                v-model="matrimonio.NombreH2"
              ></fg-input>
            </div>
            <div class="col-md-4">
              <label>Edad</label>
              <fg-input
                class="custom-input"
                type="text"
                v-model="matrimonio.EdadH2"
              ></fg-input>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6">
              <label>Nombre</label>
              <fg-input
                class="custom-input"
                type="text"
                v-model="matrimonio.NombreH3"
              ></fg-input>
            </div>
            <div class="col-md-4">
              <label>Edad</label>
              <fg-input
                class="custom-input"
                type="text"
                v-model="matrimonio.EdadH3"
              ></fg-input>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6">
              <label>Nombre</label>
              <fg-input
                class="custom-input"
                type="text"
                v-model="matrimonio.NombreH4"
              ></fg-input>
            </div>
            <div class="col-md-4">
              <label>Edad</label>
              <fg-input
                class="custom-input"
                type="text"
                v-model="matrimonio.EdadH4"
              ></fg-input>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6">
              <label>Nombre</label>
              <fg-input
                class="custom-input"
                type="text"
                v-model="matrimonio.NombreH5"
              ></fg-input>
            </div>
            <div class="col-md-4">
              <label>Edad</label>
              <fg-input
                class="custom-input"
                type="text"
                v-model="matrimonio.EdadH5"
              ></fg-input>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6">
              <label>Nombre</label>
              <fg-input
                class="custom-input"
                type="text"
                v-model="matrimonio.NombreH6"
              ></fg-input>
            </div>
            <div class="col-md-4">
              <label>Edad</label>
              <fg-input
                class="custom-input"
                type="text"
                v-model="matrimonio.EdadH6"
              ></fg-input>
            </div>
          </div>

          <div class="row">
            <div class="col-md-4">
              <label>Matrimonio Asesor</label>
              <multiselect
                class="custom-multiselect"
                v-model="matrimonio.MatrimonioAsesor"
                :options="asesoresOptions"
                :searchable="false"
                :close-on-select="true"
                :clear-on-select="false"
                :allow-empty="false"
                placeholder="Seleccione una opción"
                :show-labels="false"
                label="nombre"
                track-by="id"
              ></multiselect>
            </div>
          </div>

           <!--Datos Esposo Asesor-->
           <div class="row">
            <div class="col-md-6">
              <label>Nombre del Esposo Asesor</label>
              <fg-input class="custom-input"
              type="text"
              v-model="matrimonio.NombreEsposoAsesor">
            </fg-input>
            </div>
          </div>

          <div class="row">
            <div class="col-md-6">
              <label>Profesion</label>
              <fg-input class="custom-input"
              type="text"
              v-model="matrimonio.ProfesionEsposoAsesor">
            </fg-input>
            </div>
            <div class="col-md-6">
              <label>Numero de Identidad</label>
              <fg-input class="custom-input"
              type="text"
              v-model="matrimonio.IdentidadEsposoAsesor">
            </fg-input>
            </div>
          </div>

           <div class="row">
            <div class="col-md-6">
              <label>Numero Membresia MIC</label>
              <fg-input class="custom-input"
              type="text"
              v-model="matrimonio.MembresiaEsposoAsesor">
            </fg-input>
            </div>
            <div class="col-md-6">
              <label>No. Empleado Higuertropic</label>
              <fg-input class="custom-input"
              type="text"
              v-model="matrimonio.EmpleadoEsposoAsesor">
            </fg-input>
            </div>
          </div>

          <div class="row">
            <div class="col-md-6">
              <label>Celular</label>
              <fg-input class="custom-input"
              type="text"
              v-model="matrimonio.CelularEsposoAsesor">
            </fg-input>
            </div>
            <div class="col-md-6">
              <label>Correo Electrónico</label>
              <fg-input class="custom-input"
              type="text"
              v-model="matrimonio.CorreoEsposoAsesor">
            </fg-input>
            </div>
          </div>


           <!--Datos Esposa-->
           <div class="row">
            <div class="col-md-6">
              <label>Nombre de la Esposa Asesora</label>
              <fg-input class="custom-input"
              type="text"
              v-model="matrimonio.NombreEsposaAsesora">
            </fg-input>
            </div>
          </div>

          <div class="row">
            <div class="col-md-6">
              <label>Profesion</label>
              <fg-input class="custom-input"
              type="text"
              v-model="matrimonio.ProfesionEsposaAsesora">
            </fg-input>
            </div>
            <div class="col-md-6">
              <label>Numero de Identidad</label>
              <fg-input class="custom-input"
              type="text"
              v-model="matrimonio.IdentidadEsposaAsesora">
            </fg-input>
            </div>
          </div>

           <div class="row">
            <div class="col-md-6">
              <label>Celular</label>
              <fg-input class="custom-input"
              type="text"
              v-model="matrimonio.CelularEsposaAsesora">
            </fg-input>
            </div>
            <div class="col-md-6">
              <label>Correo Electrónico</label>
              <fg-input class="custom-input"
              type="text"
              v-model="matrimonio.CorreoEsposaAsesora">
            </fg-input>
            </div>
          </div>

          <div class="row">
            <div class="col-md-6">
              <label>Numero Membresia MIC</label>
              <fg-input class="custom-input"
              type="text"
              v-model="matrimonio.MembresiaEsposaAsesora">
            </fg-input>
            </div>
            <div class="col-md-6">
              <label>No. Empleado Higuertropic</label>
              <fg-input class="custom-input"
              type="text"
              v-model="matrimonio.EmpleadoEsposaAsesora">
            </fg-input>
            </div>
          </div>

          <!-- Otros Datos -->

          <!-- Tabla Datos Profesionales -->
          <h5 style="background-color: #f1f1f1; padding: 10px; text-align: center; color: #252422;">
            <i class="ti-plus"></i> Otros Datos
          </h5>

          <div class="row">
            <div class="col-md-6">
              <label>Nombre de la Iglesia a la que pertenece</label>
              <fg-input class="custom-input"
              type="text"
              v-model="matrimonio.NombreIglesiaActual">
            </fg-input>
            </div>
          </div>


          <div class="row">
            <div class="col-md-4">
              <label>Nombre Pastor o Sacertdote</label>
              <fg-input
                class="custom-input"
                type="text"
                v-model="matrimonio.PastorOSacerdote"
              ></fg-input>
            </div>
            <div class="col-md-4">
              <label>Celular</label>
              <fg-input
                class="custom-input"
                type="email"
                v-model="matrimonio.CelularPastor"
              ></fg-input>
            </div>
            <div class="col-md-4">
              <label>Correo Electronico</label>
              <fg-input
                class="custom-input"
                type="text"
                v-model="matrimonio.CorreoPastor"
              ></fg-input>
            </div>
          </div>

          <div class="row">
            <div class="col-md-6">
              <label>Tiempo Activo</label>
              <fg-input
                class="custom-input"
                type="text"
                v-model="matrimonio.TiempoActivo"
              ></fg-input>
            </div>
            <div class="col-md-6">
              <label>Cargo</label>
              <fg-input
                class="custom-input"
                type="text"
                v-model="matrimonio.Cargo"
              ></fg-input>
            </div>
          </div>


          <div class="text-center">
            <p-button class="btn-lg" style="margin: 40px;" type="info" round @click.native.prevent="crearMatrimonio">
              Enviar Matrimonio
            </p-button>
          </div>
          <div class="clearfix"></div>
        </form>
      </div>
    </card>
  </div>
</template>
<script>
import Multiselect from 'vue-multiselect';
import 'vue-multiselect/dist/vue-multiselect.min.css';
import matrimoniosService from '@/services/matrimonios_service.js';

export default {
  components: { Multiselect },
  data() {
    return {
      matrimonio: {
        TipoMatrimonio: "",
        NombreEsposo: "",
        ProfesionEsposo: "",
        IdentidadEsposo: "",
        MembresiaEsposo: "",
        EmpleadoHigertropicEsposo: "",
        CelularEsposo: "",
        CorreoEsposo: null,
        NombreEsposa: "",
        ProfesionEsposa: "",
        IdentidadEsposa: "",
        MembresiaEsposa: "",
        EmpleadoHigertropicEsposa: "",
        CelularEsposa: "",
        CorreoEsposa: null,
        MunicipioId: "",
        DepartamentoId: "",
        Direccion: "",
        NumeroHijos: 0,
        NombreH1: "",
        EdadH1: "",
        NombreH2: "",
        EdadH2: "",
        NombreH3: "",
        EdadH3: "",
        NombreH4: "",
        EdadH4: "",
        NombreH5: "",
        EdadH5: "",
        NombreH6: "",
        EdadH6: "",
        NombreEsposoAsesor: "",
        ProfesionEsposoAsesor: "",
        IdentidadEsposoAsesor: "",
        MembresiaEsposoAsesor: "",
        EmpleadoEsposoAsesor: "",
        CelularEsposoAsesor: "",
        CorreoEsposoAsesor: null,
        NombreEsposaAsesora: "",
        ProfesionEsposaAsesora: "",
        IdentidadEsposaAsesora: "",
        MembresiaEsposaAsesora: "",
        EmpleadoEsposaAsesora: "",
        CelularEsposaAsesora: "",
        CorreoEsposaAsesora: null,
        NombreIglesiaActual: "",
        PastorOSacerdote: "",
        CelularPastor: "",
        CorreoPastor: null,
        TiempoActivo: "",
        Cargo: "",

      },

      matrimonioOptions: ['Zonal', 'Departamental','Municipal', 'Local', 'Evangelizador'],

      hijosOptions: ['0', '1', '2', '3', '4', '5', '6'],

      asesoresOptions: [
        { id: 1, nombre: 'Si' },
        { id: 2, nombre: 'No' },
      ],

      //Inicializar variables de opciones
      municipios: [],
      departamentos: [],
      filteredMunicipios: [],
      filteredDepartamentos: [],
      //Manejo de errores
      errores: {},
      };
    },
    methods:{
      fetchCiudades() {
        matrimoniosService.getCiudades()
        .then(response => {
          this.municipios = response.data.data.map(ciudad => ({
            id: ciudad.id,
            nombre: ciudad.attributes.Nombre
          }));
          this.filteredMunicipios = this.municipios;
        })
        .catch(error => {
          console.error('Error al obtener municipios', error);
        });
    },

    fetchDepartamentos() {
      matrimoniosService.getDepartamentos()
        .then(response => {
          this.departamentos = response.data.data.map(departamento => ({
            id: departamento.id,
            nombre: departamento.attributes.Nombre
          }));
          this.filteredDepartamentos = this.departamentos;
        })
        .catch(error => {
          console.error('Error al obtener departamentos', error);
        });
    },

    mapMatrimonioAsesor(value) {
      return value === 'Si' ? 1 : 0;
    },

    async crearMatrimonio() {
    const matrimonioAsesorValue = this.mapMatrimonioAsesor(this.matrimonio.MatrimonioAsesor);
    this.errores = {}; // Reset errores to ensure it's clean before adding new errors

    try {
      const response = await matrimoniosService.postMatrimonio({
      DatosGenerales: {
      TipoMatrimonio: this.matrimonio.TipoMatrimonio,
      NombreEsposo: this.matrimonio.NombreEsposo,
      ProfesionEsposo: this.matrimonio.ProfesionEsposo,
      IdentidadEsposo: this.matrimonio.IdentidadEsposo,
      MembresiaEsposo: this.matrimonio.MembresiaEsposo,
      EmpleadoHigertropicEsposo: this.matrimonio.EmpleadoHigertropicEsposo,
      CelularEsposo: this.matrimonio.CelularEsposo,
      CorreoEsposo: this.matrimonio.CorreoEsposo,
      NombreEsposa: this.matrimonio.NombreEsposa,
      ProfesionEsposa: this.matrimonio.ProfesionEsposa,
      IdentidadEsposa: this.matrimonio.IdentidadEsposa,
      MembresiaEsposa: this.matrimonio.MembresiaEsposa,
      EmpleadoHigertropicEsposa: this.matrimonio.EmpleadoHigertropicEsposa,
      CelularEsposa: this.matrimonio.CelularEsposa,
      CorreoEsposa: this.matrimonio.CorreoEsposa,
      Municipio: this.matrimonio.MunicipioId,
      Departamento: this.matrimonio.DepartamentoId,
      Direccion: this.matrimonio.Direccion,
      NumeroHijos: this.matrimonio.NumeroHijos,
      NombreH1: this.matrimonio.NombreH1,
      EdadH1: this.matrimonio.EdadH1,
      NombreH2: this.matrimonio.NombreH2,
      EdadH2: this.matrimonio.EdadH2,
      NombreH3: this.matrimonio.NombreH3,
      EdadH3: this.matrimonio.EdadH3,
      NombreH4: this.matrimonio.NombreH4,
      EdadH4: this.matrimonio.EdadH4,
      NombreH5: this.matrimonio.NombreH5,
      EdadH5: this.matrimonio.EdadH5,
      NombreH6: this.matrimonio.NombreH6,
      EdadH6: this.matrimonio.EdadH6,
      MatrimonioAsesor: matrimonioAsesorValue,
      NombreEsposoAsesor: this.matrimonio.NombreEsposoAsesor,
      ProfesionEsposoAsesor: this.matrimonio.ProfesionEsposoAsesor,
      IdentidadEsposoAsesor: this.matrimonio.IdentidadEsposoAsesor,
      MembresiaEsposoAsesor: this.matrimonio.MembresiaEsposoAsesor,
      EmpleadoEsposoAsesor: this.matrimonio.EmpleadoEsposoAsesor,
      CelularEsposoAsesor: this.matrimonio.CelularEsposoAsesor,
      CorreoEsposoAsesor: this.matrimonio.CorreoEsposoAsesor,
      NombreEsposaAsesora: this.matrimonio.NombreEsposaAsesora,
      ProfesionEsposaAsesora: this.matrimonio.ProfesionEsposaAsesora,
      IdentidadEsposaAsesora: this.matrimonio.IdentidadEsposaAsesora,
      MembresiaEsposaAsesora: this.matrimonio.MembresiaEsposaAsesora,
      EmpleadoEsposaAsesora: this.matrimonio.EmpleadoEsposaAsesora,
      CelularEsposaAsesora: this.matrimonio.CelularEsposaAsesora,
      CorreoEsposaAsesora: this.matrimonio.CorreoEsposaAsesora,
    },

    OtrosDatos: {
      NombreIglesiaActual: this.matrimonio.NombreIglesiaActual,
      PastorOSacerdote: this.matrimonio.PastorOSacerdote,
      CelularPastor: this.matrimonio.CelularPastor,
      CorreoPastor: this.matrimonio.CorreoPastor,
      TiempoActivo: this.matrimonio.TiempoActivo,
      Cargo: this.matrimonio.Cargo,
  },
});


    this.$toast.success('Matrimonio enviado con éxito', {
      position: "top-right",
      timeout: 5000,
    });
    this.resetForm(); // Reset form fields after successful submission
    } catch (error) {
    console.error('Error de envío', error);

    this.$toast.error('Error al enviar el matrimonio', {
      position: "top-right",
      timeout: 5000,
    });
    }
    },
    resetForm() {
      this.matrimonio = {
        TipoMatrimonio: "",
        NombreEsposo: "",
        ProfesionEsposo: "",
        IdentidadEsposo: "",
        MembresiaEsposo: "",
        EmpleadoHigertropicEsposo: "",
        CelularEsposo: "",
        CorreoEsposo: "",
        NombreEsposa: "",
        ProfesionEsposa: "",
        IdentidadEsposa: "",
        MembresiaEsposa: "",
        EmpleadoHigertropicEsposa: "",
        CelularEsposa: "",
        CorreoEsposa: "",
        MunicipioId: "",
        DepartamentoId: "",
        Direccion: "",
        NumeroHijos: "",
        NombreH1: "",
        EdadH1: "",
        NombreH2: "",
        EdadH2: "",
        NombreH3: "",
        EdadH3: "",
        NombreH4: "",
        EdadH4: "",
        NombreH5: "",
        EdadH5: "",
        NombreH6: "",
        EdadH6: "",
        MatrimonioAsesor: "",
        NombreEsposoAsesor: "",
        ProfesionEsposoAsesor: "",
        IdentidadEsposoAsesor: "",
        MembresiaEsposoAsesor: "",
        EmpleadoEsposoAsesor: "",
        CelularEsposoAsesor: "",
        CorreoEsposoAsesor: "",
        NombreEsposaAsesora: "",
        ProfesionEsposaAsesora: "",
        IdentidadEsposaAsesora: "",
        MembresiaEsposaAsesora: "",
        EmpleadoEsposaAsesora: "",
        CelularEsposaAsesora: "",
        CorreoEsposaAsesora: "",
        NombreIglesiaActual: "",
        PastorOSacerdote: "",
        CelularPastor: "",
        CorreoPastor: "",
        TiempoActivo: "",
        Cargo: "",
      };

    this.errores = {};
    },


    },
    created() {
      this.fetchCiudades();
      this.fetchDepartamentos();
    },


};
</script>

<style scoped>
.custom-input {
  border: solid 1px #e4e7ea;
  margin-bottom: 25px;
}
.custom-multiselect {
  font-size: 14px;
  color: #66615b;
  margin-bottom: 25px;
}
::v-deep label {
  color: #252422 !important;
}
</style>
