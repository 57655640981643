<template>
  <div class="row">
    <div class="col-xl-12 col-lg-7 col-md-6">
      <SolicitudEmpleoTable />
    </div>
  </div>
</template>
<script>

import SolicitudEmpleoTable from "@/tables/SolicitudEmpleoTable.vue";


export default {
  components: {
    SolicitudEmpleoTable,
  },
};
</script>
<style></style>
